// import {
//   Alert,
//   Box,
//   Button,
//   CircularProgress,
//   Grid,
//   IconButton,
//   InputAdornment,
//   InputLabel,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import {
//   AppointmentLoginApi,
//   checkApiForAppointmentUser,
//   checKPhotoGrapherExist,
// } from "../../API/Auth";
// import { useNavigate } from "react-router";
// import { getphotographerList } from "../../API/Admin";
// import { useDispatch, useSelector } from "react-redux";
// import { addProfileRequest } from "../../Store/action";
// import { Visibility, VisibilityOff } from "@mui/icons-material";

// export default function AppointMentLogin({
//   appointmentNextStep,
//   setChecked,
//   email,
//   appointmentdata,
//   photoGrapherList,
// }) {
//   const [errorMessage, setErrorMessage] = useState("");
//   const [succesMessage, setSuccesMessage] = useState("");
//   const [succesStatus, setSuccesStatuse] = useState(false);

//   const [isLoading, setIsLoading] = useState(false);

//   const [showPassword, setShowPassword] = useState(false);

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const dispatch = useDispatch();
//   const [data, setData] = useState({
//     email: email || "",
//     password: "",
//   });
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));

//   const handleChange = (e) => {
//     //console.log("Test");
//     setErrorMessage("");
//     setSuccesMessage("");
//     const { name, value } = e.target;
//     const trimmedValue = value.trim();
//     setData((prev) => ({
//       ...prev,
//       [name]: trimmedValue,
//     }));
//   };

//   const checkPhotoGrapher = async () => {
//     try {
//       const response = await checKPhotoGrapherExist(data.email);

//       console.log("response12345", response);
// if(response?.data?.IsExist){
//   return "not_user";
// }
//       //console.log("response", response?.data?.IsExist);
//       photoGrapherList(response?.data?.IsExist);
//       const photoGrpherExist = response?.data?.IsExist;
//       const datas = { email: data.email, photoGrpherExist:photoGrpherExist };
//       dispatch(addProfileRequest(datas));
      
//       //console.log("response", response);
//     } catch (error) {
//       //console.log("error", error);
//     }
//   };
//   const profileData = useSelector((state) => state.user.user);
  
//   //console.log("Profiledata12", profileData);

//   const countinueStepper = async (photoGrpherExist) => {
//     try {
//       //console.log("profileData12345", profileData);

//       const userExist = profileData?.data?.userType;
//       const userData = profileData?.data?.data;
//       //console.log("userExist", userExist);

//       if (userExist) {
//         //console.log("userExist");
//         setSuccesMessage("You are successfully logged in");
//         setSuccesStatuse(true);
//         localStorage.clear();
//         localStorage.setItem("accessToken", profileData?.data?.accessToken);
//         // localStorage.setItem("userType", response?.data?.userType);
//         localStorage.setItem("userId", userData?._id);
//         localStorage.setItem("email", userData.email);
//         localStorage.setItem("profileImage", userData?.imageUrl);

//         if (userData.email === "vitthal@sumerudigital.com") {
//           localStorage.setItem("userType", "admin");
//         } else {
//           localStorage.setItem("userType", profileData?.userType);
//         }

//         // localStorage.setItem("userType", "admin");

//         // setTimeout(() => {
//         //   navigate("/home");
//         // }, 1500);

//         setTimeout(() => {
//           if (localStorage.getItem("userType") === "user") {
//             navigate("/publicGroup");
//           } else {
//             navigate("/home");
//           }
//         }, 1500);
//       } else {
//         appointmentNextStep(data.email);
//         setChecked(false);
//       }
//     } catch (error) {
//       //console.log("error", error);
//     }
//   };

//   useEffect(() => {
//     if (profileData) {
//       //console.log("UserPassed");
//       countinueStepper();
//     }
//   }, [profileData]);
  
//   const continueStepper = async () => {
//     if (!data.email) {
//       setErrorMessage("Email is Required");
//     } else if (!data.password) {
//       setErrorMessage("Password is Required");
//     } else {
//       setIsLoading(true); // Start loading
//       const startTime = Date.now();

//       try {
//         const datas = {
//           email_id: data.email,
//           password: data.password,
//         };

//         const response = await AppointmentLoginApi(datas);
//         appointmentdata(response?.data?.response);
// console.log("response",response);

//         if (
//           response?.status === 200 &&
//           response?.data?.message === "You have logged in successfully!"
//         ) {
//           // if(response.data.response?.user_role === "user"){
//           //   setSuccesStatuse("error");
//           //    setErrorMessage("This login only for photographer");
           
//           // }else{
//          const result = await checkPhotoGrapher();
//          console.log("resultresult",result);
//          if(result === "not_user"){
//             setSuccesStatuse(false);
//               setErrorMessage("This login only for photographer");
//          }else{
//           setSuccesStatuse(true);
//           setSuccesMessage("You are successfully logged in");
//          }
        
//           // }
//         } else {
//           setSuccesStatuse(false);
//           setSuccesMessage(response?.data?.message);
//         }
//       } catch (error) {
//         //console.log("error", error);
//         setSuccesStatuse(false);
//         setSuccesMessage("An error occurred. Please try again.");
//       } finally {
//         const endTime = Date.now();
//         const elapsedTime = endTime - startTime;
//         const remainingTime = Math.max(1500 - elapsedTime, 0);

//         // Ensure the loading state lasts at least 1500ms
//         setTimeout(() => {
//           setIsLoading(false); // Stop loading
//         }, remainingTime);
//       }
//     }
//   };
//   //console.log("data", data);

//   return (
//     <div
//       style={{
//         marginTop: 10,
//         display: "flex",
//         flexDirection: "column",
//         gap: "5px",
//       }}
//     >
//       {succesMessage && (
//         <Alert severity={succesStatus ? "success" : "error"}>
//           {succesMessage}
//         </Alert>
//       )}

//       <InputLabel style={{ marginTop: "10px" }}>
//         Email <span style={{ color: "red" }}>*</span>
//       </InputLabel>
//       <TextField
//         placeholder="Enter Your Email"
//         style={{ width: "100%", marginTop: 2 }}
//         name="email"
//         value={data.email}
//         onChange={handleChange}
//       />
//       {errorMessage === "Email is Required" ? (
//         <Typography style={{ textAlign: "left", color: "red" }}>
//           {errorMessage}
//         </Typography>
//       ) : null}

//       <InputLabel style={{ marginTop: "10px" }}>
//         Password <span style={{ color: "red" }}>*</span>
//       </InputLabel>
//       <TextField
//         placeholder="Enter Your Password"
//         style={{ width: "100%", marginTop: 2 }}
//         name="password"
//         type={showPassword ? "text" : "password"}
//         value={data.password}
//         onChange={handleChange}
//         InputProps={{
//           endAdornment: (
//             <InputAdornment position="end">
//               <IconButton onClick={togglePasswordVisibility} edge="end">
//                 {showPassword ? <Visibility /> : <VisibilityOff />}
//               </IconButton>
//             </InputAdornment>
//           ),
//         }}
//       />
//       {errorMessage === "Password is Required" ? (
//         <Typography style={{ textAlign: "left", color: "red" }}>
//           {errorMessage}
//         </Typography>
//       ) : null}

//       <Button
//         fullWidth
//         variant="contained"
//         onClick={() => continueStepper()}
//         style={{ marginTop: "40px", backgroundColor: "#297EFF" }}
//         disabled={isLoading}
//       >
//         {isLoading ? <CircularProgress size={30} /> : "Submit"}
//       </Button>
//     </div>
//   );
// } 




import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  AppointmentLoginApi,
  checkApiForAppointmentUser,
  checKPhotoGrapherExist,
} from "../../API/Auth";
import { useLocation, useNavigate } from "react-router";
import { getphotographerList } from "../../API/Admin";
import { useDispatch, useSelector } from "react-redux";
import { addProfileRequest } from "../../Store/action";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function AppointMentLogin({
  appointmentNextStep,
  setChecked,
  email,
  appointmentdata,
  photoGrapherList,
}) {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [succesMessage, setSuccesMessage] = useState("");
  const [succesStatus, setSuccesStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
 
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: email || "",
    password: "",
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log("sdddddddddddddddddd", location.state?.from);
  const redirectToPath = location.state?.from || '/home';


  const handleChange = (e) => {
    setErrorMessage("");
    setSuccesMessage("");
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value.trim(),
    }));
  };

  const checkPhotoGrapher = async () => {
    try {
      const response = await checKPhotoGrapherExist(data.email);
     
      const photoGrpherExist = response?.data?.IsExist;
      const datas = { email: data.email, photoGrpherExist };
      dispatch(addProfileRequest(datas));
      photoGrapherList(photoGrpherExist);
      return "photographer";
    } catch (error) {
      console.error("Error in checking photographer existence", error);
      return null;
    }
  };

  const profileData = useSelector((state) => state.user.user);
console.log("profileDataprofileData",profileData);

  const countinueStepper = async () => {
    try {
      const userExist = profileData?.data?.userType;
      const userData = profileData?.data?.data;
      if (userExist) {
        setSuccesMessage("You are successfully logged in");
        setSuccesStatus(true);
        localStorage.clear();
        localStorage.setItem("accessToken", profileData?.data?.accessToken);
        localStorage.setItem("userId", userData?._id);
        localStorage.setItem("email", userData.email);
        localStorage.setItem("profileImage", userData?.imageUrl);
        localStorage.setItem(
          "userType",
          userData.email === "vitthal@sumerudigital.com" ? "admin" : profileData?.data?.userType
        );
        console.log("sddddddddddddddddddhh", window.location.from);
        setTimeout(() => {
          
          navigate(redirectToPath);
        }, 1500);
      } else {
        appointmentNextStep(data.email);
        setChecked(false);
      }
    } catch (error) {
      console.error("Error in continuing stepper", error);
    }
  };

  useEffect(() => {
    if (profileData) {
      countinueStepper();
    }
  }, [profileData]);

  const continueStepper = async () => {
    if (!data.email) {
      setErrorMessage("Email is Required");
      
    } else if (!data.password) {
      setErrorMessage("Password is Required");
    } else {
      setIsLoading(true);
      const startTime = Date.now();
      try {
        const loginData = { email_id: data.email, password: data.password };
        const response = await AppointmentLoginApi(loginData);
        appointmentdata(response?.data?.response);

        if (response?.status === 200 && response?.data?.message === "You have logged in successfully!") {
          const result = await checkPhotoGrapher();

          if (result != "photographer") {
            console.log("gggggggggggggggggggggggggggggggggggggg");
            
            setErrorMessage("This login only for photographer");
            setSuccesStatus(false);
          } else {
            setSuccesMessage("You are successfully logged in");
            setSuccesStatus(true);
          }
        } else {
          setErrorMessage(response?.data?.message || "An error occurred.");
          setSuccesStatus(false);
        }
      } catch (error) {
        setErrorMessage("An error occurred. Please try again.");
        setSuccesStatus(false);
      } finally {
        const endTime = Date.now();
        const elapsedTime = endTime - startTime;
        const remainingTime = Math.max(1500 - elapsedTime, 0);
        setTimeout(() => setIsLoading(false), remainingTime);
      }
    }
  };


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevents the default form submission
      continueStepper();
    }
  };


  return (
    <div 
    onKeyDown={handleKeyDown}
    style={{ marginTop: 10, display: "flex", flexDirection: "column", gap: "5px" }}>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {succesMessage && <Alert severity={succesStatus ? "success" : "error"}>{succesMessage}</Alert>}

      <InputLabel style={{ marginTop: "10px" }}>
        Email <span style={{ color: "red" }}>*</span>
      </InputLabel>
      <TextField
        placeholder="Enter Your Email"
        style={{ width: "100%", marginTop: 2 }}
        name="email"
        value={data.email}
        onChange={handleChange}
      />
      {errorMessage === "Email is Required" && (
        <Typography style={{ textAlign: "left", color: "red" }}>{errorMessage}</Typography>
      )}

      <InputLabel style={{ marginTop: "10px" }}>
        Password <span style={{ color: "red" }}>*</span>
      </InputLabel>
      <TextField
        placeholder="Enter Your Password"
        style={{ width: "100%", marginTop: 2 }}
        name="password"
        type={showPassword ? "text" : "password"}
        value={data.password}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {errorMessage === "Password is Required" && (
        <Typography style={{ textAlign: "left", color: "red" }}>{errorMessage}</Typography>
      )}

      <Button
        fullWidth
        variant="contained"
        onClick={continueStepper}
         sx={buttonStyle}
       // style={{ marginTop: "40px", backgroundColor: "#297EFF" }}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={30} /> : "Submit"}
      </Button>
    </div>
  );
}

const buttonStyle = {
  marginTop: "16px",
  borderColor: "#DB4437",
  color: "#DB4437",
  padding: "12px 20px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
    color: "#fff",
  },
};

// import { Alert, Button, InputLabel, TextField, Typography } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
// import { AppointmentLoginApi, checkApiForAppointmentUser, checKPhotoGrapherExist } from '../../API/Auth';
// import { useNavigate } from 'react-router';
// import { getphotographerList } from '../../API/Admin';

// export default function AppointMentLogin({ appointmentNextStep, setChecked, email, appointmentdata,photoGrapherList }) {
//   const [errorMessage, setErrorMessage] = useState("");
//   const [succesMessage, setSuccesMessage] = useState("");
//   const [succesStatus, setSuccesStatuse] = useState(false);
//   const [data, setData] = useState({
//     email: email || "",
//     password: ""
//   });
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));

//   const handleChange = (e) => {
//     //console.log("Test")
//     setErrorMessage("");
//     setSuccesMessage("");
//     const { name, value } = e.target;
//     const trimmedValue = value.trim();
//     setData((prev) => ({
//       ...prev,
//       [name]: trimmedValue
//     }));
//   };

//   // const checkPhotoGrapher=async()=>{
//   //    try {
//   //     const response = await getphotographerList()
//   //     const allPhotoGrapher=response?.data?.data?.map((item)=>item.email)
//   //     photoGrapherList(allPhotoGrapher)
//   //     setAllphotoGrapher(allPhotoGrapher)

//   //     //console.log("response",allPhotoGrapher)
//   //    } catch (error) {

//   //    }
//   // }
//   // useEffect(()=>{
//   //   checkPhotoGrapher()
//   // },[])

//   const checkPhotoGrapher=async()=>{
//     try {
//      const response = await checKPhotoGrapherExist(data.email)

//      //console.log("response",response?.data?.IsExist)
//       photoGrapherList(response?.data?.IsExist)
//      const photoGrpherExist=response?.data?.IsExist
//      CheckUserExist(photoGrpherExist);

//      //console.log("response",response)
//     } catch (error) {
//      //console.log("error",error)
//     }
//  }

//   const CheckUserExist = async (photoGrpherExist) => {

//     const datas = { email: data.email };
//     try {
//       const response = await checkApiForAppointmentUser(datas);
//       const userExist = response?.data?.data?.userType;

//       if (userExist) {
//         setSuccesMessage("You are successfully logged in");
//         setSuccesStatuse(true);
//         localStorage.clear();
//         localStorage.setItem("accessToken", response?.data?.accessToken);
//         // localStorage.setItem("userType", response?.data?.userType);
//         localStorage.setItem("userId", response?.data?.data?._id);
//         localStorage.setItem("email", response?.data?.data?.email);
//         localStorage.setItem("profileImage", response?.data.data?.imageUrl);

//         if(photoGrpherExist===true){
//              //console.log("Passed")
//              localStorage.setItem("userType","photographer");
//             //  localStorage.setItem("userType","admin");

//         }
//         else{
//           //console.log("NotPassed")
//             localStorage.setItem("userType", response?.data?.userType);
//           //  localStorage.setItem("userType","admin");
//           // localStorage.setItem("userType", "admin");

//         }
//         setTimeout(() => {
//           navigate("/");
//         }, 1500);
//       } else {
//         appointmentNextStep(email);
//         setChecked(false);
//       }
//     } catch (error) {
//       //console.log("error", error);
//     }
//   };

//   const continueStepper = async () => {
//     if (!data.email) {
//       setErrorMessage("Email is Required");
//     } else if (!data.password) {
//       setErrorMessage("Password is Required");
//     } else {
//       try {
//         const datas = {
//           email_id: data.email,
//           password: data.password,
//         };

//         const response = await AppointmentLoginApi(datas);
//         //console.log("response1234567", response);
//         appointmentdata(response?.data?.response)
//         if (response?.status === 200 && response?.data?.message === "You have logged in successfully!") {
//           //console.log('Test');
//           checkPhotoGrapher()
//           const email = response?.data?.response?.email_id;
//           // CheckUserExist(email);
//         } else {
//           setSuccesStatuse(false);
//           setSuccesMessage(response?.data?.message);
//         }
//       } catch (error) {
//         //console.log("error", error);
//       }
//     }
//   };

//   //console.log("data", data);

//   return (
//     <div style={{ marginTop: 10 }}>
//       {succesMessage &&
//         <Alert severity={succesStatus ? 'success' : "error"}>{succesMessage}</Alert>
//       }

//       <InputLabel>Email <span style={{ color: 'red' }}>*</span></InputLabel>
//       <TextField
//         placeholder='Enter Your Email'
//         style={{ width: "100%", marginTop: 2 }}
//         name="email"
//         value={data.email}
//         onChange={handleChange}
//       />
//       {errorMessage === "Email is Required" ? <Typography style={{ textAlign: "left", color: "red" }}>{errorMessage}</Typography> : null}
//       <InputLabel style={{ marginTop: "10px" }}>Password <span style={{ color: 'red' }}>*</span></InputLabel>
//       <TextField
//         placeholder='Enter Your Password'
//         style={{ width: "100%", marginTop: 2 }}
//         name='password'
//         value={data.password}
//         onChange={handleChange}
//       />
//       {errorMessage === "Password is Required" ? <Typography style={{ textAlign: "left", color: "red" }}>{errorMessage}</Typography> : null}

//       <Button fullWidth variant='outlined' onClick={() => continueStepper()} style={{ marginTop: "10px" }}>Submit</Button>
//     </div>
//   );
// }
