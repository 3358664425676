// import { InputLabel, Stack, TextField, Typography } from '@mui/material'
// import React, { useState } from 'react'
// import 'react-phone-input-2/lib/style.css'
// import Alert from '@mui/material/Alert';

// export default function Login4({Login4Data,errorMessage}) {
//   const [userData, setUserData] = useState({
//        name: '',
//       studioName: '',
//       phone: '',
//       studiolocation: '',
//     file: null
//   });

//   const handleChange = (e) => {
//     const { name, value, type } = e.target;
//     if (type === 'file') {
//        const file = e.target.files[0];
//        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
//          setUserData({
//            ...userData,
//            [name]: file
//          });
//          Login4Data({ ...userData, [name]: file });
//        } else {
//          alert('Please select a JPG or PNG file.');
//        }
//     } else if (name === 'phone') {
//        // Filter out non-digit characters
//        const digitsOnly = value.replace(/\D/g, '');
//        setUserData({
//          ...userData,
//          [name]: digitsOnly
//        });
//        Login4Data({ ...userData, [name]: digitsOnly });
//     } else {
//        setUserData({
//          ...userData,
//          [name]: value
//        });
//        Login4Data({ ...userData, [name]: value });
//     }
//    };
   
//   return (
//     <>
//         <Stack spacing={1} style={{marginTop:50}}>
//           <div>

//         </div>


//         <div>
//   <Typography>
//     Choose your profile photo <span style={{color:'red'}}>*</span>
//   </Typography>
//   <TextField style={{ width: "100%" }} type='file' name='file' onChange={handleChange} />
//   {errorMessage && 
//         <Alert severity="error" style={{ textAlign: "left", marginTop: "5px" }}>
//           {errorMessage}
//         </Alert>
//       }
// </div>
//         </Stack>
//         </>
  
//   )
// }



import { Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import Alert from "@mui/material/Alert";

export default function Login4({ Login4Data, errorMessage, url,name }) {
  console.log('base data', url, name);
  const [userData, setUserData] = useState({
    name: name||'',
    phone: '',
    file: null,
  });
  const [imagePreview, setImagePreview] = useState(url||"");
  const [formErrors, setFormErrors] = useState({ phone: "" });
  const handleChange = (e) => {
    const { name, value, type } = e.target;
  
    const updatedUserData = {
      ...userData,
      userType: "user",
    };
  
    if (type === "file") {
      const file = e.target.files[0];
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        updatedUserData[name] = file;
        setImagePreview(URL.createObjectURL(file));
      } else {
        alert("Please select a JPG/JPEG or PNG file.");
      }
    } else if (name === "phone") {
      const digitsOnly = value.replace(/\D/g, "");
      updatedUserData[name] = digitsOnly;
  
      if (digitsOnly.length !== 10) {
        setFormErrors({
          ...formErrors,
          phone: "Phone number must be exactly 10 digits",
        });
      } else {
        setFormErrors({
          ...formErrors,
          phone: "",
        });
      }
    } else {
      updatedUserData[name] = value;
    }
      setUserData(updatedUserData);
    Login4Data(updatedUserData);
  };
  
  // const handleChange = (e) => {
  //   const { name, value, type } = e.target;
  //   if (type === "file") {
  //     const file = e.target.files[0];
  //     if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
  //       setUserData({
  //         ...userData,
  //         [name]: file,
  //       });
  //       setImagePreview(URL.createObjectURL(file)); // Set image preview URL
  //       Login4Data({ ...userData, [name]: file, userType: "user" });
  //     } else {
  //       alert("Please select a JPG/JPEG or PNG file.");
  //     }
  //   } else if (name === "phone") {
  //     // Filter out non-digit characters
  //     const digitsOnly = value.replace(/\D/g, "");
  //     setUserData({
  //       ...userData,
  //       [name]: digitsOnly,
  //     });

  //     // Validate phone number length
  //     if (digitsOnly.length !== 10) {
  //       setFormErrors({
  //         ...formErrors,
  //         phone: "Phone number must be exactly 10 digits",
  //       });
  //     } else {
  //       setFormErrors({
  //         ...formErrors,
  //         phone: "",
  //       });
  //     }

  //     Login4Data({ ...userData, [name]: digitsOnly });
  //   } else {
  //     setUserData({
  //       ...userData,
  //       [name]: value,
  //     });
  //     Login4Data({ ...userData, [name]: value });
  //   }
 
  // };

  return (
    <>
      <Stack spacing={0.2} style={{ marginTop: 10 }}>
        {imagePreview && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={imagePreview}
              alt="Profile Preview"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </div>
        )}
        {errorMessage && (
          <Alert
            severity="error"
            style={{ textAlign: "left", marginTop: "2px" }}
          >
            {errorMessage}
          </Alert>
        )}
        <div>
          <Typography>
            Name <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            style={{ width: "100%" }}
            type="text"
            name="name"
            placeholder="Name is required"
            value={userData.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <Typography>
            Phone Number <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            style={{ width: "100%" }}
            type="text"
            placeholder="phone Number is required"
            name="phone"
            value={userData.phone}
            onChange={handleChange}
            error={!!formErrors.phone}
            helperText={formErrors.phone}
          />
        </div>
        {/* <div>
          <Typography>
            Choose your profile photo <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            style={{ width: "100%" }}
            type="file"
            name="file"
            onChange={handleChange}
          />
        </div> */}

<div>
          <Tooltip title="Upload a photo of only your face; photos with multiple faces are not allowed">
            <Typography>
              Choose your profile photo <span style={{ color: "red" }}>*</span>
            </Typography>
         
          <TextField
            style={{ width: "100%" }}
            type="file"
            name="file"
            onChange={handleChange}
          />
           </Tooltip>
        </div>
      </Stack>
      
    </>
  );
}




