import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, MenuItem, Rating } from '@mui/material';
import { feedBack } from '../../API/feedbackApi';
import { useSelector } from 'react-redux';

const FeedbackModal = ({ open, onClose }) => {
    const [rating, setRating] = useState(0);
    const [satisfaction, setSatisfaction] = useState('');
    const [feedback, setFeedback] = useState('');
    const [status, setStatus] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [successPopupOpen, setSuccessPopupOpen] = useState(false);

    const profileData = useSelector((state) => state.user.user);
    //console.log("feedback,profileData", profileData);

    const sendFeedback = async () => {
        const data = {
            userEmail: profileData.data.data.email,
            rating: rating,
            satisfactionLevel: satisfaction,
            feedbackText: feedback,
        };

        try {
            const response = await feedBack(data);
            //console.log("feedback response", response);
            setStatus(response?.status);

            if (response?.status === 200) {
                //console.log("feedback responseData", response?.data?.Message);
                setSuccessMessage("Feedback submitted successfully.");
                setSuccessPopupOpen(true);  // Open the success popup
            } else {
                setSuccessMessage(response?.response?.data?.Message || "Failed to submit feedback.");
            }
        } catch (error) {
            //console.log(error);
            setSuccessMessage("An error occurred while submitting feedback.");
        }
    };

    const handleSuccessPopupClose = () => {
        setSuccessPopupOpen(false);
        onClose(); // Close the main feedback modal
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                        Feedback
                    </Typography>
                    <Box component="div" sx={{ mb: 3 }}>
                        <Typography component="legend">Rate Us</Typography>
                        <Rating
                            name="star-rating"
                            value={rating}
                            onChange={(event, newValue) => setRating(newValue)}
                        />
                    </Box>
                    <Box component="div" sx={{ mb: 3 }}>
                        <TextField
                            select
                            label="Satisfaction Level"
                            value={satisfaction}
                            onChange={(event) => setSatisfaction(event.target.value)}
                            fullWidth
                        >
                            <MenuItem value="Very Satisfied">Very Satisfied</MenuItem>
                            <MenuItem value="Satisfied">Satisfied</MenuItem>
                            <MenuItem value="Not Satisfied">Not Satisfied</MenuItem>
                            <MenuItem value="It is too bad">It is too bad</MenuItem>
                        </TextField>
                    </Box>
                    <Box component="div" sx={{ mb: 3 }}>
                        <TextField
                            label="Write your feedback"
                            multiline
                            rows={4}
                            value={feedback}
                            onChange={(event) => setFeedback(event.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Button variant="contained" color="primary" onClick={sendFeedback}>
                        Submit
                    </Button>
                    {status && (
                        <Typography variant="body2" color={status === 200 ? 'green' : 'red'} sx={{ mt: 2 }}>
                            {successMessage}
                        </Typography>
                    )}
                </Box>
            </Modal>

            <Modal open={successPopupOpen} onClose={handleSuccessPopupClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                        Thank You!
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        Thanks for giving your feedback.
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleSuccessPopupClose}>
                        Close
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default FeedbackModal;
