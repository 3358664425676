import {
  Button,
  Card,
  Typography,
  Grid,
  Box,
  TextField,
  Paper,
  Divider,
  Stack,
  Select,
  MenuItem,
  IconButton,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha, ThemeProvider, createTheme } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import { useLocation, useNavigate } from "react-router";
import {
  GetEventApi,
  getPrivateGroup,
  getPublicGroup,
  getPublicUserGroup,
} from "../../API/Photographer";
import Loader from "../Component/loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "../../css/Public.css";
import RangeDatePicker from "../Component/DatePicker";
import { SearchField } from "../Component/SerachField";
import CardEvent from "../DynmicaComponment/CardEvent";
import { useSelector } from "react-redux";
import createAlbumICon from "../../images/create-album-icon.svg";

const maxWidth = 1400;

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  borderColor: "#8CBAE8",
  borderWidth: 1,
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  borderColor: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function PublicGroup() {
  const [open, setOpen] = React.useState(false);
  const [geteventName, setGetEventName] = useState([]);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [load, setLoad] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchCriteria, setSearchCriteria] = useState({
    name: "",
    city: "",
    country: "",
    area: "",
    slot: ""
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultImage = "https://pbs.twimg.com/media/CW6hXDiUoAArGhx.jpg";
  const [loadss, setLoadss] = useState(false);
  const profileData = useSelector((state) => state.user.user);
  const [SuccessMessage, setSuccessMessage] = useState({
    message: "",
    status: null,
  });
  const location = useLocation();
  const urlId = new URLSearchParams(location.search).get('id');
//   useEffect(()=>{

// if(urlId != null){
//   console.log("hhhhhhhh",urlId);
 
//   searchProps(urlId)
//     setValue(urlId)
// }
// },[urlId]);

  const UserList = (item) => {
    console.log("item",item);
    
    const eventProps = item;
    navigate("/upload", { state: { eventProps } });
  };
  const showButton = profileData?.userType === 'photographer';

  const getEvented = async (dates) => {
    try {
      const response = await GetEventApi(dates);
      //console.log("hjhjh", response);
      setGetEventName(response?.data?.data || []);
    } catch (error) { }
  };

  const getEvent = async (dates) => {
    //console.log("profileData", profileData);
    const userType = profileData.userType;
    setLoadss(true);
    try {
      let response;
      console.log("hkjsh",userType)
      if (userType === "photographer") {
        response = await getPublicGroup(dates);
      } else {
        response = await getPublicUserGroup(dates);
      }
      const eventData = response?.data?.data || [];
      //console.log("eventDataeventDatadddddddddddddddddd", eventData);
      setGetEventName(eventData);
      return eventData;
    } catch (error) {
      console.error("Error fetching event data:", error);
      setGetEventName([]);
      return [];
    } finally {
      setLoadss(false);
    }
  };

  useEffect(() => {
    const urlId = new URLSearchParams(location.search).get('event');
    console.log("Event ID:", urlId);

    const fetchData = async () => {
      try {
        if (urlId) {
          const dates = {
            startDate: "",
            endDate: "",
            search: urlId,
          };
          const res = await getEvent(dates); // Pass dates if `getEvent` needs it
          console.log("Event Data:", res);
          const eventProps = res[0];

          navigate("/upload", { state: {eventProps } });
        } else {
          // const profile = await getProfile();
          // console.log("Profile Data:", profile);

          const event = await getEvent(); // Call without parameters if needed
          console.log("Default Event Data:", event);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location]);

// useEffect(() => {
//   const fetchData = async () => {
//     // await getEvented();
//     if(urlId != null){
//       console.log("hhhhhhhh",urlId);
//       const datas = {
//         startDate: "",
//         endDate: "",
//         search: urlId,
//       };
//       await getEvent(datas);
      
//     }else{
//       await getEvent();
//     }
//   };

//   fetchData();
// }, [profileData,urlId]); 

  const onDate = (data) => {
    //console.log("fsdfgfdgdfgdfgdf", data);
    const dat = data.split("#");
    //console.log("hhh", dat);
    const dates = {
      startDate: dat[0],
      endDate: dat[1],
      search: searchValue,
    };

    // getEvented(dates);
    getPublicGroup(dates);
  };

  //---------------sticky scroll Header -----------------------//
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setIsScrolling(true);
    let scrollTimeout;
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      setIsScrolling(false);
    }, 200);
  };

  const handleSearchChange = (data) => {
    const urlPrefix = "http://localhost:3000/";
  
    const processedData = data.startsWith(urlPrefix) ? data.replace(urlPrefix, "") : data;
  
    setSearchValue(processedData);
    const datas = {
      startDate: "",
      endDate: "",
      search: processedData,
      ...searchCriteria,
    };
    getEvent(datas);
    // getEvented(datas)
  };

  const handleSearchCriteriaChange = (field, value) => {
    setSearchCriteria((prev) => ({ ...prev, [field]: value }));
    const datas = {
      startDate: "",
      endDate: "",
      search: searchValue,
      [field]: value,
    };
    getEvent(datas);
  };

  const formatDate = (date) => {
    const today = new Date();
    const eventDate = new Date(date);
  
    const isToday = today.toDateString() === eventDate.toDateString();
    const isYesterday =
      today.getDate() - 1 === eventDate.getDate() &&
      today.getMonth() === eventDate.getMonth() &&
      today.getFullYear() === eventDate.getFullYear();
  
    const diffInTime = today.getTime() - eventDate.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
  
    const isThisMonth =
      today.getMonth() === eventDate.getMonth() &&
      today.getFullYear() === eventDate.getFullYear();
  
    const isThisYear =
      today.getFullYear() === eventDate.getFullYear();
  
    if (isToday) return "Today";
    if (isYesterday) return "Yesterday";
    if (diffInDays <= 7) return "Last 7 days";
    if (isThisMonth) return "This Month";
    if (isThisYear) return "This Year";
  
    return "Earlier";
  };

  const groupByDate = (events) => {
    //console.log("hhhhhhhhhhhhh", events);
    return events.reduce((acc, event) => {
      const dateGroup = formatDate(event.eventDate);
      if (!acc[dateGroup]) {
        acc[dateGroup] = [];
      }
      acc[dateGroup].push(event);
      return acc;
    }, {});
  };
  const [groupedEvents, setGroupedEvents] = useState({});

  useEffect(() => {
    setGroupedEvents(groupByDate(geteventName));
  }, [geteventName]);
  //console.log('group event 1234', geteventName);


  const renderEvents = () => {
    if (loadss) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "80px",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      );
    }

    // Check if there are any events in groupedEvents
    const hasEvents = Object.keys(groupedEvents).length > 0;

    if (!hasEvents) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "80px",
          }}
        >
          <Typography variant="h6" color="textSecondary">
            No Results Found
          </Typography>
        </div>
      );
    }

    return Object.keys(groupedEvents).map((date, index) => (
      <div key={date} style={{ maxWidth: `${maxWidth}px`, margin: "0 auto" }}>
        <Typography
          variant="body5"
          style={{
            paddingLeft: "35px",
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "20px",
            marginBottom: "0px",
            display: "flex",
            width: "100px",
            height: "24px",
          }}
        >
          {date}
        </Typography>
        <Grid container spacing={2} marginTop={isMobileOrTablet ? 1 : 0}>
          {groupedEvents[date].map((item, itemIndex) => (
            <Grid item xs={12} sm={6} md={3} key={itemIndex}>
              <ThemeProvider theme={theme}>
                <Stack direction="column" alignItems="center">
                  <div
                    style={{
                      paddingTop: "5px",
                      height: "100%",
                      width: "100%", // Ensure the div takes full width
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "40px"
                    }}
                  >
                    <CardEvent
                      item={item}
                      onCardClick={UserList}
                      defaultImage={defaultImage}
                      style={{ width: "100%", maxWidth: "280px" }}
                    />
                  </div>
                </Stack>
              </ThemeProvider>
            </Grid>
          ))}
        </Grid>
      </div>
    ));
  };

  // const groupedEvents = useMemo(() => groupByDate(geteventName), [geteventName]);

  return (
    <div>
      {load && <Loader open={load} />}
      <div
        style={{
          position: "sticky",
          top: "60px",
          zIndex: "999",
          width: "100%",
        }}
      >
        <div>
          <Card
            style={{
              marginTop: 10,
              width: "100%",
              backgroundColor: "#F4FAFF",
            }}
            elevation={0}
          >
            {isMobileOrTablet ? (
              <Grid container spacing={2} sx={{ padding: "10px" }}>
                <Grid item xs={12} sm={12}></Grid>
                <Grid item xs={12} sm={12}>
                  <RangeDatePicker width={"100%"} filterDate={onDate} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >

                  <SearchField
                    width={"400px"}
                    searchProps={handleSearchChange}
                    placeholder=""
                  />
                </Grid>
              </Grid>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  padding: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <Grid >
                    {/* {showButton && (<Button
                      height="20px"
                      fullWidth
                      variant="text"
                      // onClick={handleOpenForCreate}
                      style={{
                        textTransform: "none",
                        fontWeight: "600",
                        backgroundColor: "white",
                        border: "1px solid #D4D4D4",
                      }}
                    >
                      <img
                        style={{ marginRight: "10px" }}
                        src={createAlbumICon}
                        alt=""
                        srcset=""
                      />
                      Create Album
                    </Button>)} */}

                  </Grid>
                </div>
                <div>
                  <Stack
                    direction={"row"}
                    gap={2}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      padding: 10,
                    }}
                  >
                    <RangeDatePicker width={"100%"} filterDate={onDate} />
                    <SearchField
                      width="400px"
                      searchProps={handleSearchChange}
                      style={{ backgroundColor: "white" }}
                    />
                  </Stack>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
      <Stack marginTop={"60px"} marginLeft={2} spacing={2} alignItems={isMobile ? 'flex-start' : 'center'}>
        <Stack direction="column" alignItems={isMobile ? 'flex-start' : 'center'}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#3974CB",
              mt: 2,
            }}
          >
            Public Album
          </Typography>
        </Stack>

        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            textAlign: isMobile ? 'left' : 'center',
            mt: 5,
            mb: 7,
            maxWidth: 800,
          }}
        >
          All public albums are below. Use our AI face detection to easily locate your photos from events you attended. Browse the albums and let AI do the rest!

        </Typography>
      </Stack>
      <div>{renderEvents(groupedEvents)}</div>
    </div>
  );
}
