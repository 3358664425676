
// import { Navigate, useRoutes } from 'react-router-dom';
// import PrivateRoute from './priveteRoute';
// import PagesRoute from './pageRoute';
// import AdminRoute from './adminRoute';
// import { useMemo, useState } from 'react';

// export default function ThemeRoutes() {
//     const [isAuthenticated, setIsAuthenticated] = useState(false);
//     const [userType, setUserType] = useState('');
//     const tk = localStorage.getItem('accessToken');

//     useMemo(() => {
//       const token = localStorage.getItem('accessToken');
//       const userType = localStorage.getItem('userType');
//       //console.log("dfdfdff", token, userType);
//       setIsAuthenticated(!!token);
//       setUserType(userType || '');
//     }, [tk]);

//     let routes = [];
//     if (isAuthenticated) {
//       if (userType === "admin") {
//         //console.log("admin")
//         // routes.push(AdminRoute,PagesRoute);
//         routes = AdminRoute;
//       } else if (userType === "user") {
//         routes = PagesRoute;
//       }
//     } else {
//       routes = PrivateRoute;
//     }

//     //console.log("routesroutes", routes);
//     return useRoutes([
//       ...routes,
//       { path: '*', element: <Navigate to="/" replace /> }
//     ]);
// }

import { Navigate, useRoutes } from 'react-router-dom';
import PrivateRoute from './priveteRoute';
import { PhotographerRoutes,UserRoutes } from './pageRoute';
import AdminRoute from './adminRoute';
import { useMemo, useState } from 'react';

export default function ThemeRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState('');
  const tk = localStorage.getItem('accessToken');

  useMemo(() => {
    const token = localStorage.getItem('accessToken');
    const userTypeFromStorage = localStorage.getItem('userType');



    setIsAuthenticated(!!token);
    setUserType(userTypeFromStorage || '');
  }, [tk]); // Assuming 'tk' is a typo and meant to be 'token'



  let routes = [];

  
  if (isAuthenticated) {
    if (userType === "admin") {
      //console.log("Admin");
      // Concatenate AdminRoute and PagesRoute when userType is admin
      routes = [...AdminRoute, ];
      // routes = [...AdminRoute, ...PagesRoute];
    } else if (userType === "user") {
      routes = UserRoutes;
    }else if(userType === "photographer"){
      routes = PhotographerRoutes;
    } else {
      routes = PrivateRoute();
    }
  } else {
    routes = PrivateRoute();
  }

  //console.log("Final Routes:", routes);
  return useRoutes([
    ...routes,
    { path: '*', element: <Navigate to="/" replace /> }
  ]);


}


