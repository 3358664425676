// import React from 'react';
// import { MuiOtpInput } from 'mui-one-time-password-input';
// import { Typography, Alert } from '@mui/material';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
// import { useDispatch, useSelector } from 'react-redux';
// import { addProfileData ,updateProfile} from "../../Store/reducer"
// import { useNavigate } from 'react-router';
// import { addProfileRequest } from '../../Store/action';
// import { otpVerify } from '../../API/Auth';

// export default function Login2({ Login2Data, errorMessage, userEmail, moveNextStep }) {
//   const [otp, setOtp] = React.useState('');
//   const [errorMessages, setErrorMessages] = React.useState(errorMessage);
//   const [status, setStatus] = React.useState('');
//   const theme = useTheme();
//   const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const handleChange =async (newValue) => {
//     setErrorMessages('');
//     const newValues = newValue.replace(/[^0-9]/g, '');
//     setOtp(newValues);

//     if (newValue.length === 6) {
//       //console.log("Test")
//       const data = {
//         email: userEmail,
//         otp: newValue
//       };
//       const res = await otpVerify(data);
//       console.log("dfghjk",res);
// if(res.status === 200){
//   if(res?.userType === null ){
    
//   }
//   localStorage.clear();
//         localStorage.setItem("accessToken", res?.data?.accessToken);
//         localStorage.setItem("userType", res?.data?.userType);
//         localStorage.setItem("userId", res?.data?.data?._id);
//         localStorage.setItem("email", res?.data?.data?.email);
//         localStorage.setItem("profileImage", res?.data?.data?.imageUrl);
//   navigate('/home');
//   dispatch(addProfileRequest(data));

// }
      
//     }

//     Login2Data(newValue);
//   };

//   const profileData = useSelector((state) => state.user.user);
//   //console.log('Profiledata1235',profileData)
//   React.useEffect(() => {
//      if(profileData==="Invalid OTP"){
//         setErrorMessages("Wrong OTP")
//      }

//     else if(profileData?.Message==="OTP verified successfully") {
//       //console.log("Else")
//       setStatus(200)
    
//       if (profileData?.userType === null) {
//         moveNextStep();
//       } else {
//         localStorage.clear();
//         localStorage.setItem("accessToken", profileData?.accessToken);
//         localStorage.setItem("userType", profileData?.userType);
//         localStorage.setItem("userId", profileData?.data?._id);
//         localStorage.setItem("email", profileData?.data?.email);
//         localStorage.setItem("profileImage", profileData?.data?.imageUrl);
//         setErrorMessages("Logged in Successfully");

//         setTimeout(() => {
//           setErrorMessages('');
//           navigate('/home');
//         }, 1000);
//       }
//      } 
//   }, [profileData, moveNextStep, navigate]);

//   return (
//     <div>
//       {errorMessage || errorMessages ? (
//         <Alert severity={status === 200 ? "success" : "error"}>
//           {errorMessage ? errorMessage : errorMessages}
//         </Alert>
//       ) : null}

//       <div style={{ marginTop: 10 }}>
//         <Typography style={{ textAlign: "center" }}>
//           An OTP has been sent to <span style={{ fontWeight: "bold" }}>{userEmail}</span>
//         </Typography>
//         <div style={{ marginTop: 10 }}>
//           <MuiOtpInput value={otp} onChange={handleChange} length={6} />
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useEffect } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Typography, Alert } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { otpVerify } from '../../API/Auth';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { addProfileRequest } from '../../Store/action';

export default function Login2({ Login2Data, errorMessage, userEmail, moveNextStep, otpReset }) {
  const [otp, setOtp] = React.useState('');
  const [errorMessages, setErrorMessages] = React.useState(errorMessage);
  const [status, setStatus] = React.useState('');
  const [isOtpValid, setIsOtpValid] = React.useState(false);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectToPath = location.state?.from || '/';

  useEffect(() => {
    if (otpReset === true) {
      setOtp('');
    }
  }, [otpReset]);

  const handleChange = async (newValue) => {
    setErrorMessages("");
   
    if (/^\d*$/.test(newValue)) {
      setOtp(newValue);

      if (newValue.length === 6) {
        try {
          const data = {
            email: userEmail,
            otp: newValue
          };

          const response = await otpVerify(data);
          console.log("responseresponse", response?.data?.userType);

          setStatus(response.status);
          if (response.status === 200) {
            setErrorMessages("");
            setIsOtpValid(true);
            if (response?.data?.userType === null) {
              moveNextStep();
            } else {
              localStorage.clear();
              localStorage.setItem("accessToken", response?.data?.accessToken);
              localStorage.setItem("userId", response?.data?.data?._id);
              localStorage.setItem("email", response?.data?.data?.email);
              localStorage.setItem("profileImage", response?.data.data?.imageUrl);
              localStorage.setItem("userType", response?.data.data?.userType);
              const photoGrpherExist = response?.data?.IsExist;
              const datas = { email: response?.data?.data?.email };
              console.log("datasdatas", datas);

              const ss = dispatch(addProfileRequest(datas));
              setErrorMessages("Logged in Successfully");

              setTimeout(() => {
                setErrorMessages('');
                navigate(redirectToPath);
              }, 2000);
            }
          } else {
            setIsOtpValid(false);
            setErrorMessages("Wrong OTP");

            setTimeout(() => {
              setErrorMessages('');
            }, 5000);
          }
        } catch (err) {
          setIsOtpValid(false);
          console.error(err);
          setErrorMessages(err.message);
        }
      } else {
        setIsOtpValid(false);
      }

      Login2Data({ otp: newValue, email: userEmail });
    }
  };

  return (
    <div>
      {errorMessage || errorMessages ? (
        <Alert severity={status === 200 ? "success" : "error"}>
          {errorMessage ? errorMessage : errorMessages}
        </Alert>
      ) : null}

      <div style={{ marginTop: 10 }}>
        <Typography style={{ textAlign: "center" }}>
          An OTP has been sent to <span style={{ fontWeight: "bold" }}>{userEmail}</span>
        </Typography>
        <div style={{ marginTop: 10 }}>
          <MuiOtpInput 
            display="flex"
            gap={isMobileOrTablet ? 0.3 : 2.5}
            value={otp}
            onChange={handleChange}
            length={6}
            validateChar={(value) => /^\d+$/.test(value)}
          />
        </div>
      </div>
    </div>
  );
}
















































// import React from 'react'
// import { MuiOtpInput } from 'mui-one-time-password-input'
// import { Typography ,Alert } from '@mui/material'
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles'
// import { otpVerify } from '../../API/Auth';
// import { useNavigate} from 'react-router';
// export default function Login2({Login2Data,errorMessage,userEmail ,moveNextStep}) {
//   const [otp, setOtp] = React.useState('')
//   const [errorMessages, setErrorMessages] = React.useState(errorMessage)
//   const [status, setStatus] = React.useState('')
//   const theme = useTheme();
//   const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
//   const navigate=useNavigate()

//   //console.log("errorMessage",errorMessage)
//   //console.log("errorMessagessss",errorMessages)
//   const handleChange = async(newValue) => {
//     setErrorMessages("");
//     const newValues = newValue.replace(/[^0-9]/g, '');
//     setOtp(newValues)
//     ////console.log("newValue",newValue)

//     if(newValue.length===6){
    
      
//       try {
//         // if (!otp) {
//         //   setErrorMessage("This field is required");
//         // } else if (otp.length !== 6) {
//         //   setErrorMessage("Enter all 6 digits");
//         // }else{
//         const data = {
//           email: userEmail,
//           otp:newValue
//         };
  
//         const response = await otpVerify(data);
//         console.error("response.message",response);
//         setStatus(response.status)
//         if (response.status === 200) {
//           setErrorMessages("");
//           if(response?.data?.userType === null ){
//             // setCurrentStep(currentStep + 1);
//             moveNextStep()
//           }else{
//             localStorage.clear();
//               localStorage.setItem("accessToken",response?.data?.accessToken);
//               localStorage.setItem("userId",response?.data?.data?._id);
//               localStorage.setItem("email",response?.data?.data?.email);
//               localStorage.setItem("profileImage",response?.data.data?.imageUrl);
       
//               setErrorMessages("Logged in Successfully")
                 
//               setTimeout(()=>{
//                 setErrorMessages('')
//                 navigate('/home');
//               },1000)
//           }
      
//         }else{
//           setErrorMessages("Wrong OTP");
//         }
//       // }
//       } catch (err) {
//         console.error(err);
//         setErrorMessages(err.message)
//       }


//     }

//     //console.log("newValue",newValue.length)
//     Login2Data(newValue)
//   }



  

//   return (
//     <div >
//        {
//           errorMessage || errorMessages ? <Alert  severity= {status===200?  "success":"error"} >{errorMessage ? errorMessage: errorMessages}</Alert>: null
//       } 

//     <div style={{marginTop:10}}>
//       <Typography style={{textAlign:"center"}}>An OTP has been sent to <span style={{fontWeight:"bold"}}>{userEmail}</span></Typography>
//       <div style={{marginTop:10}}>
//     <MuiOtpInput value={otp} onChange={handleChange} length={6} />
//      </div>
//     </div>
//     </div>
//   )
// }


