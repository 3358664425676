import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Avatar,
  Backdrop,
  Card,
  CircularProgress,
  InputLabel,
  Pagination,
  Stack,
  setRef,
  FormHelperText,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
} from "@mui/material";
import useDrivePicker from "react-google-drive-picker";
import {
  DownloadImage,
  deleteImageApi,
  fileCheking,
  getAllImages,
  faceDetectedImagesApi,
  uploadImage,
  getProfileApi,
  getDetectedHighImages,
  getDetectedLowImages,
  UploadDetedImages,
  GetUploadDectedImages,
  UploadProgress,
} from "../API/Photographer";
import { getaddedUser } from "../API/Auth";
import { Grid } from "@mui/material";
// import LazyLoadedImage from './component/lazyLoadImage';
import { useLocation, useNavigate } from "react-router";
import noImage from "../images/noiamge.jpg";
import "../css/Drive.css";
import SubscriptionPay from "./payment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "../css/Gallary.css";
import DeleteImages from "./deleteImage";
import ViewImages from "./viewImages";
import SuccessProgress from "./Component/successProgress";

import { Clear, PersonAdd, Upload } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateUserImage } from "../Store/action";
import { styled } from "@mui/material/styles";
// import CircularProgress, {
//   circularProgressClasses,
// } from '@mui/material/CircularProgress';
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Profile from "./Header/Profile/profile";
import albummm from "../../src/images/photoalbum-icon.svg";
import locaTIIIION from "../../src/images/location-2-icon.svg";
import timmmmm from "../../src/images/date-time-slot-2-icon.svg";
import gallery from "../../src/images/create-album-icon.svg";
import { CloseCircleTwoTone } from "@ant-design/icons";
import Avrcard from "./Component/Avrcard";
import AvatarDetectLoader from "./Component/AvatarDetectLoader";
import AvatarLoader from "./Component/AvatarLoader";

export default function UploadImages() {
  const [open, setOpen] = useState(false);
  const navigator = useNavigate();
  const [selectedFile, setSelectedFile] = useState();
  const [uploadPercentage, setuploadpercentage] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState(); // Add this line
  const [finalPage, setFinalPage] = useState("");
  const [selectedUrl, setSelectedUrl] = useState([]);
  const [deleteOpen, setdeleteOpen] = useState(false);
  const [deleteData, setdeleteData] = useState("");
  const [ownPhoto, setOwnPhoto] = useState([]);
  const [myPhotos, setMyPhotos] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [detectedCount, setDetectedCount] = useState(0);
  const [renderKey, setRenderKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const [ownPhotoExist, setOwnPhotoExist] = useState("");
  const [status, setStatus] = useState();
  const [finalResponseData, setfinalResponseData] = useState("");
  const [highImageData, setHighImagesData] = useState("");
  const [isDetctedLoder, setisDetctedLoder] = useState(false);
  const [detectedImages, setDetectedImages] = useState([]);
  const [finaldetectedImages, setFinaldetectedImages] = useState([]);
  const [finalImagesLoader, setfinalImagesLoader] = useState(false);
  const location = useLocation();
  const fetchImagesRef = useRef(false);
  
  const eventData = location.state?.eventProps;
  //console.log("eventDAtaaa", eventData);
  // const imageUrl = location.state?.imageUrl;
  const [uploadProgress, setUploadProgress] = useState(0);

  const [userData, setUserData] = useState({
    getUser: [],
    userProfile: {},
  });

  const dispatch = useDispatch();
  const eventTab = location?.state;
  const eventTabs = navigator?.state?.eventTab;
  // state: states
  //console.log("Receiveduserdata:", location.state);

  const profileData = useSelector((state) => state.user);
  //console.log("profileData12335", profileData);

  const userId = profileData?.user?.UserId;
  useEffect(() => {
    getAllAddedUser();
  }, []);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
  ////console.log("eventData",eventData)
  const handleOpen = () => setOpen(true);

  const [value, setValue] = useState(0);
  const [values, setValues] = useState(0);
  const [loader, setloader] = useState(false);

  const [loaderScroll, setloaderScroll] = useState(false);
  const [loaderForModel, setloderForModel] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobileOrTablet ? 300 : 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  //console.log(values, "valuesssss");
  //console.log(detectedImages, "valuesdetectedImagesssss");
  //console.log(ownPhoto, "ownPhoto");

  //----------------Google Drive picker------------------//
  const [openPicker, authResponse] = useDrivePicker();
  // const [authToken, setAuthToken] = useState(authResponse?.access_token);
  let authToken = authResponse?.access_token;
  localStorage.setItem("ggToken", authResponse?.access_token);

  const subscriptionMessage = () => { };

  useEffect(() => {
    authToken = authResponse?.access_token;
  }, [authResponse]);

  const handleOpenPicker = () => {
    const pickerCallback = (data) => {
      if (data.action === "cancel") {
      } else if (data.action === "picked") {
        handleDriveFileSelection(data); // Assuming data.docs contains selected files
      }
    };

    openPicker({
      clientId:
        "174146068432-ovvbm90df3r06nrq7osdq7v2akjnio9c.apps.googleusercontent.com",
      developerKey: "AIzaSyASL3yMev0JmvprcVv_VXnHFV2oy8k5MGE",
      viewId: "DOCS_IMAGES_AND_VIDEOS",
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: true,
      callbackFunction: pickerCallback,
    });
  };

  const handleDriveFileSelection = async (data) => {
    setErrorMessage("");

    if (!localStorage.getItem("ggToken")) {
      return;
    }

    const actualFiles = await Promise.all(
      data.docs.map(async (file) => {
        const response = await fetch(
          `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`,
          {
            headers: new Headers({
              Authorization: "Bearer " + localStorage.getItem("ggToken"),
            }),
          }
        );
        const blob = await response.blob();
        const newFile = new File([blob], file.name, { type: blob.type });
        return newFile;
      })
    );

    setSelectedFile(actualFiles);
    localStorage.removeItem("ggToken");
  };

  //-----------------------------------------------------//
  //Loading

  // const ITEMS_PER_PAGE = 40;
  const [ITEMS_PER_PAGE,setITEMS_PER_PAGE]=useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");
  //----------------------Image scrol---------------------//

  const getUserType = () => {
    return localStorage.getItem("userType") || "user";
  };

  const userType = getUserType();
  //console.log("UserType:", userType);

  const getMyphotoExist = async (data) => {
    try {
      const currentPages = 1;
      const status = "myPhotos";
      const response = await getAllImages(
        status,
        currentPages,
        eventData?.eventCode
      );
      //console.log("response123456", response);
      setOwnPhotoExist(response?.data?.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  //console.log("eventData12345", eventData);

  const getUploadDetectedImageInAUth = async (datas) => {
    console.log("Testtttt",currentPage);

    try {
      setITEMS_PER_PAGE(16);
      setloader(true);
      setfinalImagesLoader(true);
      const data = {
        userId: datas?._id,
        albumId: location?.state?.eventProps?._id,
        page: currentPage,
      };
      const response = await GetUploadDectedImages(data);
      console.log("GetUploadDectedImages", response?.data?.page);
      console.log("GetUploadDectedImages1", response?.data?.data );
      setFinalPage(response?.data?.finalPage);
      const newImages = response?.data?.data || [];
      setTotalPage(response?.data?.totalPages);
      setCurrentPage(response?.data?.page || 1)
      // setFinaldetectedImages((prevImages) => [...prevImages, ...newImages]);
      // setFinaldetectedImages(response?.data?.data || [])
      setFinaldetectedImages((prevImages) => {
        // Merge previous images with the new response data
        const mergedImages = response?.data?.data ||[];
        
        // Filter out duplicates based on `_id`
        const uniqueImages = mergedImages.filter(
            (image, index, self) =>
                index === self.findIndex((img) => img._id === image._id)
        );
    
        return uniqueImages;
    });
      setDetectedCount(response?.data?.totalImages || 0); // Set the totalCount

      setfinalImagesLoader(false);
      setloader(false)
    } catch (error) {
      // setfinalImagesLoader(false);
      setloader(false)
      //console.log("error", error);
    }
  };

  const getImages = async (data) => {
    console.log("values",);
    setITEMS_PER_PAGE(40);
    setloader(true);
    try {
      let currentPages = currentPage;
      if (data !== undefined) {
        currentPages = data;
      }

    

      const status = values === 0 ? "allPhotos" : "myPhotos";
      if(status === "allPhotos"){
      const response = await getAllImages(
        status,
        currentPages,
        eventData?.eventCode
      );

      // console.log("responnnseee12345",response?.data?.data?.totalCount);
      setfinalResponseData(response);

      if (
        response?.data?.Pages.tillImagesget === response?.data?.data?.totalCount
      ) {
        setloader(false);
        setloaderScroll(false);
      }

      const newImages = response?.data?.data?.uploads;

      if (newImages?.length === 0) {
        setloader(false);
      }

      setCurrentPage(response?.data?.Pages.currentPage || 1);
      setTotalPage(response?.data?.Pages.totalPage);

      // Set counts based on the status
      if (status === "allPhotos") {
        setTotalCount(response?.data?.data?.totalCount || 0);
      } else if (status === "myPhotos") {
        setUploadedCount(response?.data?.data?.totalCount || 0);
      }

      const updatedImages = newImages?.map((image) => ({
        ...image,
        currentPage: response?.data?.data?.currentPage,
      }));

      if (status === "myPhotos") {
        setOwnPhoto((prevData) => {
          const uniqueNewImages = updatedImages?.filter(
            (newImage) =>
              !prevData?.some((prevItem) => prevItem?._id === newImage?._id)
          );
          return [...prevData, ...uniqueNewImages];
        });

        //console.log("ownPhotos123", ownPhoto);
      }

      setSelectedUrl((prevData) => {
        const uniqueNewImages = updatedImages?.filter(
          (newImage) =>
            !prevData?.some((prevItem) => prevItem?._id === newImage?._id)
        );
        return [...prevData, ...uniqueNewImages];
      });
      //console.log("selectedUrl1234", selectedUrl);
      setloaderScroll(false);
      setloader(false);
    }
    } catch (error) {
      console.error("Error fetching images:", error);
      setloader(false);
    }
  };

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  useEffect(() => {
    if(values === 0){
      getImages();
    }else{
    getUploadDetectedImageInAUth(selectedAvatar);
    }
  }, [currentPage,values, myPhotos, deleteOpen]);

  // Add pagination controls
const handleNextPage = () => {
  if (currentPage < totalPage) {
      setCurrentPage((prevPage) => prevPage + 1);
  }
};

const handlePreviousPage = () => {
  if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
  }
};
  // const handleScroll = useCallback(() => {
  //   if (
  //     finalResponseData?.data?.Pages.tillImagesget <
  //     finalResponseData?.data?.data?.totalCount
  //   ) {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop + 1 >=
  //       document.documentElement.scrollHeight
  //     ) {
  //       // Only update if not currently loading
  //       if (!loaderScroll) {
  //         if (!finalPage || currentPage < finalPage) {
  //           //console.log("Scroll allowed, updating page:",currentPage);
  //           setCurrentPage((prevPage) => prevPage + 1);
  //           setloaderScroll(true);
  //         }
  //         // Load more data based on the new page number
  //       }
  //     }
  //   }
  // }, [finalResponseData, loaderScroll, currentPage, finalPage]);

  // Debounced handleScroll
  // const debouncedHandleScroll = useCallback(debounce(handleScroll, 300), [
  //   handleScroll,
  // ]);

  // useEffect(() => {
  //   window.addEventListener("scroll", debouncedHandleScroll);
  //   return () => window.removeEventListener("scroll", debouncedHandleScroll);
  // }, [debouncedHandleScroll]);

  // const handleScroll = () => {

  //   if (finalResponseData?.data?.Pages.tillImagesget < finalResponseData?.data?.data?.totalCount) {
  //     if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
  //       setCurrentPage(prevPage => prevPage + 1);
  //       //console.log("currentPage2", currentPage)
  //       // setLoad(true)
  //       setloaderScroll(true)
  //     }

  //   }

  // };

  // const debouncedHandleScroll = debounce(handleScroll, 300);

  // useEffect(() => {
  //   window.addEventListener('scroll', debouncedHandleScroll);
  //   return () => window.removeEventListener('scroll', debouncedHandleScroll);
  // }, [currentPage, totalPage, debouncedHandleScroll]);



  useEffect(() => {
    // detectesPhotos()
    getMyphotoExist();
  }, []);
  //-----------------------------------------------------------------------//

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFinaldetectedImages([])
    setTotalPage('');
    setCurrentPage(1);
  };

  const handleChanges = (event, newValue) => {
    console.log("kkkkk",newValue);
    
    setValues(newValue);
    setFinaldetectedImages([]);
    setTotalPage('');
    setCurrentPage(1);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile("");
    setFileExist("");
    setErrorMessage("");
    setuploadpercentage("");
    setStatus("");
    setloderForModel(false);
    // setFiles([])
    setTotalProgress(0);
  };

  //console.log(finalResponseData, "finalResponseData");

  // const fileChange = (event) => {
  //  setStatus('')
  //   setErrorMessage('')
  //   const selectedFile = Array.from(event.target.files);
  //   //console.log("selectedFiles", selectedFile);
  //   setSelectedFile(selectedFile);
  // };
  //-----------------------fileCheck API -------------------//
  const [fileExist, setFileExist] = useState([]);
  const fileCheck = async (data, selectedFiles) => {
    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });
      const res = await fileCheking(data, eventData?.eventCode, formData);
      setloderForModel(false);

      const fileExist = res.data.data;

      const newImageFileNames = fileExist
        ?.filter((item) => item.exists === "New image")
        .map((item) => item.fileName);

      const filteredSelectedFiles = selectedFiles?.filter((file) =>
        newImageFileNames?.includes(file.name)
      );

      setSelectedFile(filteredSelectedFiles);

      setFileExist(res.data.data);
    } catch (err) {
      //console.log(err);
    }
  };
  //   const fileChange = (event) => {

  //     const selectedFiles = Array.from(event.target.files);
  //     setFileExist(selectedFiles);
  //     // setloderForModel(true)
  //     //  setStatus('')
  //     setErrorMessage('');

  // };

  const fileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const allowedExtensions = /(\.jpg|\.jpeg)$/i;

    const filteredFiles = selectedFiles.filter((file) =>
      allowedExtensions.test(file.name)
    );

    if (filteredFiles.length > 0) {
      setFileExist(filteredFiles);
      setErrorMessage("");
    } else {
      setFileExist([]);
      setErrorMessage("Please select only JPG or JPEG files.");
    }
    event.target.value = "";
  };

  //console.log("fileExist", fileExist);
  // //console.log("fileExistfileExistfileExist",fileExist);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.async = true;
    script.onload = () => {
      ////console.log("Test", window.gapi); // Check if gapi is defined
    };
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  useEffect(() => {
    if (fetchImagesRef.current) {
      getImages();
      fetchImagesRef.current = false;
    }
  }, [fetchImagesRef.current]);

  // const addImages = async () => {
  //   if (fileExist === undefined) {
  //     setErrorMessage("Please select at least one image");
  //   } else if (fileExist.length === 0) {
  //     setErrorMessage("Please select at least one image");
  //   } else {
  //     setloderForModel(true);
  //     setErrorMessage("");
  //     try {
  //       const formData = new FormData();
  //       fileExist.forEach((file) => {
  //         formData.append("files", file);
  //       });
  //       const userId = localStorage.getItem("userId");

  //       const data = {
  //         event: eventData?.eventCode,
  //         eventName: eventData?.name,
  //         eventType: eventData?.eventType,
  //         email: eventData?.createdByEmail,
  //         eventCreatedBy: eventData.createdBy,
  //         userId,
  //       };
  //       formData.append("data", JSON.stringify(data));

  //       const response = await uploadImage(formData, setUploadProgress);
  //       //console.log("uploadImageNewimages", response);
  //       if (response.status === 200) {
  //         const uploadId = response?.data?.uploadId;

  //         let progressVarible = 0; // Initialize the progress variable
  //         let progressModelOpen = 0;
  //         while (progressVarible < 100) {
  //           try {
  //             const uploadResponse = await UploadProgress(uploadId);
  //             const progress = uploadResponse?.data?.progress || 0;
  //             //console.log("progress123456", progress);

  //             // Update the progress variable
  //             progressVarible = progress;
  //             setuploadpercentage(Math.floor(progress));
  //             //console.log("ProgressResponse", uploadResponse);
  //             //console.log("progressVaribleInside", progressVarible);
  //             progressModelOpen = 1;
  //             //console.log("progressModelOpen", progressModelOpen);

  //             if (progress === 100) {
  //               getImages();
  //               break;
  //             }
  //             if (progressModelOpen > 0) {
  //               //console.log("Values123456", progress);
  //               // setloderForModel(false);
  //             }
  //           } catch (error) {
  //             console.error("Error during upload progress:", error);
  //             break; // Exit the loop if there's an error
  //           }

  //           await new Promise((resolve) => setTimeout(resolve, 1000));
  //         }
  //         //console.log("progressModelOpen1", progressModelOpen);
  //       }

  //       fetchImagesRef.current = true;
  //       setRenderKey((prevKey) => prevKey + 1);

  //       setSelectedUrl([]);

  //       if (response?.status === 200) {
  //         getMyphotoExist();
  //         setStatus(response?.status);
  //         setMyPhotos(true);
  //       } else {
  //         setStatus(response?.response?.status);
  //       }

  //       setErrorMessage(response?.response?.data?.message);
  //       if (response?.status === 200) {
  //         getMyphotoExist();
  //         setSelectedFile("");
  //         // setloderForModel(false);
  //         setErrorMessage(response?.data?.message);
  //         setTimeout(() => {
  //           handleClose();
  //         }, 2000);
  //       }
  //       // setloderForModel(false);
  //     } catch (error) {
  //       //console.log("error", error);
  //     }
  //   }
  // };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  ////console.log("loader", loader)

  const handleDownload = async (datas) => {
    try {
      const urlss = datas?.fileUrl.split("/");

      const lastTwoIndices = urlss.slice(-2);
      let uri = lastTwoIndices.join("/");

      let modifiedUri = uri;
      if (uri.includes("%20")) {
        modifiedUri = uri.replace(/%20/g, " ");
      }

      const data = {
        key: modifiedUri,
      };
      const res = await DownloadImage(data);
      ////console.log("response", res);
      const fileName = data.key;
      const imageData = new Uint8Array(res.data.Body.data);
      const contentType = res.ContentType;
      const imageBlob = new Blob([imageData.buffer], { type: contentType });

      const url = window.URL.createObjectURL(imageBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      ////console.log(err);
    }
  };

  function subscriptionStatus(data) {
    if (data === 200) {
      setStatus("");
      setErrorMessage("");
    }
  }

  //--------------------------Gallery View------------------//
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const openGallery = (url, name, page, fileName) => {
    //console.log("url", url, name, page, fileName);

    const data = {
      Url: url,
      filename: name,
      currentPage: page,
      totalPage: totalPage,
      originalName: fileName,
    };

    //console.log("Data", data);
    setdeleteOpen(true);
    setdeleteData(data);
    //  navigator('/ViewImages', { state: { data } })
  };

  const viewGallerys = (url, name, page, fileName, item) => {
    const data = {
      Url: url,
      filename: name,
      currentPage: page,
      totalPage: totalPage,
      originalName: fileName,
      item: item,
    };

    //console.log("Data", data);
    setdeleteOpen(true);
    setdeleteData(data);
    // navigator('/DeleteImages', { state: { data } })
  };
  const closeDeleteImage = (data) => {
    console.log("gggggggg",currentPage)
    setdeleteOpen();
    setloader(false)
  };

  const closeGallery = () => {
    setSelectedImageIndex(null);
    setIsGalleryOpen(false);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => prevIndex - 1);
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => prevIndex + 1);
  };

  const handleAddNewUser = () => {
    navigator("/profile");
  };
  const galleryView = () => {
    return (
      <Modal
        open={isGalleryOpen}
        onClose={closeGallery}
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "black",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <IconButton onClick={goToPreviousImage}>
            <ChevronLeftIcon />
          </IconButton>
          <img src={selectedImageIndex} alt="Selected" />
          <IconButton onClick={goToNextImage}>
            <ChevronRightIcon />
          </IconButton>
        </div>
      </Modal>
    );
  };

  //---------------------------Gallery End------------------//

  const [adduserLoad, setAddUserLoad] = useState(false);
  const getAllAddedUser = async () => {
    try {
      const response = await getaddedUser();
      setAddUserLoad(false);
      //console.log("response123", response?.data?.data);
      setUserData({
        getUser: response?.data?.data?.AdditionalUsers || [],
        userProfile: response?.data?.data[0] || {},
      });
      setSelectedAvatar(response?.data?.data?.AdditionalUsers[0] || []);
    } catch (error) {
      //console.log("response error", error);
    }
  };

  const handleNavigate = (image) => {
    //console.log("image", image);
    const userImage = image?.imageUrl;
    const userId = image?._id;
    const data = {
      userImage,
      userId,
    };
    dispatch(updateUserImage(data));
    // navigator("/", { state: { imageUrl: image } });
  };

  const bytesToMB = (bytes) => {
    if (bytes === 0) return "0 MB";
    const mb = bytes / (1024 * 1024);
    return `${mb.toFixed(2)} MB`;
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 13,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background: "linear-gradient(90deg, #e01cd5 0%, #1CB5E0 100%)",
    },
  }));

  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");

  //console.log("values", values);

  const [startDetect, setStartDetect] = useState(false);

  const avatars = [
    {
      id: 1,
      username: "Username1",
      src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG276J99WMZdlsHfn17ZukWcAHXgSATWaU_Q&s",
    },
    {
      id: 2,
      username: "Username2",
      src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG276J99WMZdlsHfn17ZukWcAHXgSATWaU_Q&s",
    },
    {
      id: 3,
      username: "Username3",
      src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG276J99WMZdlsHfn17ZukWcAHXgSATWaU_Q&s",
    },
    {
      id: 4,
      username: "Username4",
      src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG276J99WMZdlsHfn17ZukWcAHXgSATWaU_Q&s",
    },
  ];

  const handleAvatarSelect = (id) => {
    setSelectedAvatar(id);
    //console.log("hhh", id);
  };

  const detectImages = (datas) => {
    setStartDetect(true);
    setCurrentPage(1);
    //console.log("fffffffffffffffffffffff", datas);
    const userImage = datas?.imageUrl;
    const userId = datas?._id;

    // dispatch(updateUserImage(data))
    getUploadDetectedImageInAUth(datas);
  };

  const handleAvatarClick = (item) => {
    setDetectedCount("");
    setSelectedAvatar(item);
    // setCurrentPage(1)
  };

  const search = () => {
    setFinaldetectedImages("");
    setCurrentPage(1);
    setFinalPage("");
    //console.log("fffffffffffffffffffffff", selectedAvatar?.imageUrl);
    const userImage = selectedAvatar?.imageUrl;
    const userId = selectedAvatar?._id;

    // dispatch(updateUserImage(data))
    getUploadDetectedImageInAUth(selectedAvatar);
  };

  const [files, setFiles] = useState([]);
  // const [uploadProgress, setUploadProgress] = useState({});
  const [openModal, setOpenModal] = useState(true);
  const [totalProgress, setTotalProgress] = useState(0);

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
    setOpen(true);
    setOpenModal(true);
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      alert("Please select at least one image");
      return;
    }

    setOpen(true);
    const batches = [];
    for (let i = 0; i < files.length; i += 1) {
      batches.push(files.slice(i, i + 1));
    }

    let totalUploaded = 0;

    for (const batch of batches) {
      await uploadBatch(batch);
      totalUploaded += batch.length;
      setTotalProgress((totalUploaded / files.length) * 100);
    }
    setOpenModal(false);
  };

  const uploadBatch = async (batch) => {
    const formData = new FormData();
    batch.forEach((file) => formData.append("files", file));

    try {
      const userId = localStorage.getItem("userId");

      const data = {
        event: eventData?.eventCode,
        eventName: eventData?.name,
        eventType: eventData?.eventType,
        email: eventData?.createdByEmail,
        eventCreatedBy: eventData.createdBy,
        userId,
      };

      formData.append("data", JSON.stringify(data));
      const response = await uploadImage(formData, setUploadProgress);

      const uploadId = response?.data?.uploadId;
      await Promise.all(batch.map((file) => trackProgress(uploadId, file)));
    } catch (error) {
      console.error("Error during upload:", error);
    }
  };

  const trackProgress = async (uploadId, file) => {
    let progress = 0;
    while (progress < 100) {
      try {
        const uploadResponse = await UploadProgress(uploadId);
        progress = uploadResponse?.data?.progress || -1;
        //console.log(`Progress for ${file.name}: ${progress}%`); // Debug log

        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: progress === -1 ? 100 : progress,
        }));

        if (progress === 100 || progress === -1) {
          break;
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
      } catch (error) {
        console.error(`Error during upload progress for ${file.name}:`, error);
        break;
      }
    }
  };

  const closeModal = () => {
    // setOpen(false);
    // setSelectedFile("");
    // setFileExist("");
    // setErrorMessage("");
    // setuploadpercentage("");
    // setStatus("");
    // setloderForModel(false);
    // // setFiles([])
    // setTotalProgress(0);
    fetchImagesRef.current = true;
    setRenderKey((prevKey) => prevKey + 1);

    setSelectedUrl([]);

    // if (response?.status === 200) {
    getMyphotoExist();
    // setStatus(response?.status);
    setMyPhotos(true);
    // } else {
    // setStatus(response?.response?.status);
    // }

    // setErrorMessage(response?.response?.data?.message);
    // if (response?.status === 200) {
    // getMyphotoExist();
    setSelectedFile("");
    // setloderForModel(false);
    // setErrorMessage(response?.data?.message);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  return (
    <div key={renderKey}>
      <div style={{ zIndex: 10, overflowX: "hidden" }}>
        {deleteOpen && values === 1 && (
          <DeleteImages
            deleteData={deleteData}
            eventData={eventData}
            closeDeleteImage={closeDeleteImage}
          />
        )}

        {
          // deleteOpen &&  values===1 && <ViewImages deleteData={deleteData} closeDeleteImage={closeDeleteImage}/>
        }

        {deleteOpen && values === 0 && (
          <ViewImages
            deleteData={deleteData}
            eventData={eventData}
            closeDeleteImage={closeDeleteImage}
          />
        )}
      </div>

      {galleryView()}

      <div>
        <Box
          style={{
            padding: isMobile ? 5 : 10,
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            backgroundColor: "#F4FAFF",
            marginTop: isMobile ? 20 : 70,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={isMobile ? 1 : 2}>
            <img
              src={albummm}
              alt="Gallery Icon"
              style={{ width: 18, height: 18, color: "#616161" }}
            />
            <Typography
              style={{
                fontSize: isMobile ? 14 : 16,
                color: "#616161",
                whiteSpace: "nowrap",
              }}
            >
              Album Details
            </Typography>
          </Stack>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              flexDirection: isMobile ? "column" : "row",
              marginTop: isMobile ? 10 : 0,
            }}
          >
            <Stack
              direction={isMobile ? "column" : "row"}
              alignItems="center"
              spacing={isMobile ? 1 : 2}
            >
              <Typography
                style={{
                  fontSize: isMobile ? 16 : 18,
                  fontWeight: "bold",
                  color: "#1565C0",
                  whiteSpace: "nowrap",
                }}
              >
                {eventData?.name} | {eventData?.venueAlias}
              </Typography>
              <Typography
                style={{
                  fontSize: isMobile ? 12 : 14,
                  color: "#616161",
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={locaTIIIION}
                  alt="Location Icon"
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
                {eventData?.area}
              </Typography>
              <Typography
                style={{
                  fontSize: isMobile ? 12 : 14,
                  color: "#616161",
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={timmmmm}
                  alt="Time Icon"
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
                {eventData?.eventDate &&
                  new Date(eventData?.eventDate).toLocaleDateString(
                    "en-GB"
                  )}{" "}
                | {eventData?.slot}
              </Typography>
            </Stack>

            <Box style={{ marginTop: isMobile ? 10 : 0 }}>
              <Stack>
                {(values === 0 || values === 1) &&
                  userType === "photographer" && (
                    <Button
                      disableElevation
                      onClick={handleOpen}
                      variant="contained"
                      style={{
                        height: 40,
                        textTransform: "none",
                        backgroundColor: "white",
                        color: "#1E88E5",
                        display: "flex",
                        alignItems: "center",
                        marginTop: isMobile ? 10 : -25,
                        marginRight: isMobile ? 0 : 30,
                      }}
                      size="large"
                    >
                      <img
                        src={gallery}
                        alt="Gallery Icon"
                        style={{ width: 18, height: 18, marginRight: 8 }}
                      />
                      Upload Images
                    </Button>
                  )}
              </Stack>
            </Box>
          </Box>
        </Box>

        {/* changes UI END */}
        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'fixed', width: '100%', zIndex: 1, backgroundColor: 'white' }}> */}
        {/* <Typography style={{ textAlign: 'left', marginLeft: 10, fontSize: 30 }}>{eventData?.name}</Typography> */}

        {userType === "user" &&
          userData?.getUser &&
          startDetect &&
          isMobileOrTablet && (
            // <Tooltip key={index} title={item.name} arrow>
            //   <Avatar
            //     src={item.imageUrl}
            //     style={{
            //       height: 55,
            //       width: 55,
            //       margin: 7,
            //       cursor: "pointer",
            //       borderRadius: "50%"
            //     }}
            //     onClick={() => handleNavigate(item)}
            //   />
            // </Tooltip>
            <Box
              display="flex"
              alignItems="center"
              p={1}
              bgcolor="#F4FAFF"
              borderRadius="8px"
              sx={{ margin: "5px" }}
            >
              <Typography
                s
                sx={{
                  textTransform: "none",
                  color: "#0f62fe",
                  fontWeight: "bold",
                  marginRight: "16px",
                }}
              >
                Change User
              </Typography>
              <Box display="flex" alignItems="center">
                {userData?.getUser?.map((item, index) => (
                  <Grid item key={item._id}>
                    <Box
                      onClick={() => handleAvatarClick(item)}
                      sx={{
                        display: "inline-flex",
                        flexDirection: "column",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        src={item.imageUrl}
                        alt={item.name}
                        sx={{
                          width: 26,
                          height: 26,
                          marginTop: "2px",
                          // marginBottom: 1,
                          borderWidth: "4px",
                          borderStyle: "solid",
                          borderColor: "transparent",
                          background:
                            selectedAvatar._id === item._id
                              ? `radial-gradient(circle, #297EFF, #00C3FF)`
                              : "none",
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={search}
                disableElevation
                // startIcon={<SearchIcon />}
                sx={{
                  textTransform: "none",
                  marginLeft: "16px",
                  height: "25px",
                }}
              >
                Search Photos
              </Button>
            </Box>
          )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Tabs
              value={values}
              onChange={handleChanges}
              aria-label="basic tabs example"
            >
              {userType === "photographer" && [
                <Tab
                  key="all-photos"
                  label={
                    <span>
                      All photos{" "}
                      <span
                        style={{
                          backgroundColor: "#1266F1",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2px 6px",
                        }}
                      >
                        {totalCount || 0}
                      </span>
                    </span>
                  }
                  sx={{ marginRight: 1, textTransform: "none", fontSize: 18 }}
                />,
                <Tab
                  key="uploaded-photos"
                  label={
                    <span>
                      Photos uploaded by me{" "}
                      <span
                        style={{
                          backgroundColor: "#1266F1",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2px 6px",
                        }}
                      >
                        {uploadedCount || 0}
                      </span>
                    </span>
                  }
                  sx={{ marginLeft: 2, textTransform: "none", fontSize: 18 }}
                />,
              ]}

              {userType === "user" && userData?.getUser && selectedAvatar && [
                <Tab
                label={`All Photos(${totalCount || 0
                    })`}
                sx={{marginLeft: 2, textTransform: "none", fontSize: 18}}
                />,
                <Tab
                  label={`${selectedAvatar?.name} detected photos (${detectedCount || 0
                    })`}
                  sx={{ marginLeft: 2, textTransform: "none", fontSize: 18 }}
                />
              ]}
              {/* 
              <div style={{ display: "flex" }}>
                {userType === "user" &&
                  userData?.getUser?.map((item, index) => (
                    <Tooltip key={index} title={item.name} arrow>
                      <Avatar
                        src={item.imageUrl}
                        style={{
                          height: 55,
                          width: 55,
                          margin: 7,
                          cursor: "pointer",
                          borderRadius: "50%",
                        }}
                        onClick={() => handleNavigate(item)}
                      />
                    </Tooltip>
                  ))}

                {(values === 0 || values === 1) && userType === "user" && ( */}
              <div style={{ display: "flex" }}>
                {userType === "user" &&
                values === 1 &&
                  userData?.getUser &&
                  startDetect &&
                  !isMobileOrTablet && (
                    // <Tooltip key={index} title={item.name} arrow>
                    //   <Avatar
                    //     src={item.imageUrl}
                    //     style={{
                    //       height: 55,
                    //       width: 55,
                    //       margin: 7,
                    //       cursor: "pointer",
                    //       borderRadius: "50%"
                    //     }}
                    //     onClick={() => handleNavigate(item)}
                    //   />
                    // </Tooltip>
                    <Box
                      display="flex"
                      alignItems="center"
                      p={1}
                      bgcolor="#F4FAFF"
                      borderRadius="8px"
                      sx={{ margin: "5px" }}
                    >
                      <Typography
                        s
                        sx={{
                          textTransform: "none",
                          color: "#0f62fe",
                          fontWeight: "bold",
                          marginRight: "16px",
                        }}
                      >
                        Change User
                      </Typography>
                      <Box display="flex" alignItems="center">
                        {userData?.getUser?.map((item, index) => (
                          <Grid item key={item._id}>
                            <Box
                              onClick={() => handleAvatarClick(item)}
                              sx={{
                                display: "inline-flex",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <Avatar
                                src={item.imageUrl}
                                alt={item.name}
                                sx={{
                                  width: 26,
                                  height: 26,
                                  marginTop: "2px",
                                  // marginBottom: 1,
                                  borderWidth: "4px",
                                  borderStyle: "solid",
                                  borderColor: "transparent",
                                  background:
                                    selectedAvatar._id === item._id
                                      ? `radial-gradient(circle, #297EFF, #00C3FF)`
                                      : "none",
                                }}
                              />
                            </Box>
                          </Grid>
                        ))}
                      </Box>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={search}
                        disableElevation
                        // startIcon={<SearchIcon />}
                        sx={{
                          textTransform: "none",
                          marginLeft: "16px",
                          height: "25px",
                        }}
                      >
                        Search Photos
                      </Button>
                    </Box>
                  )}

                {/* {(values === 0 || values === 1) && userType === 'user' && (
                  <Button
                    onClick={handleAddNewUser}
                    variant="outlined"
                    style={{
                      zIndex: 1,
                      borderRadius: "50%",
                      height: 50,
                      width: 50,
                      minWidth: 0,
                      marginTop: "10px",
                    }}
                    size="large"
                  >
                    <AddIcon />
                  </Button>
                )} */}
              </div>
            </Tabs>
          </div>
          {/* <div>
          
            {(values === 0 || values === 1) && userType === 'photographer' && (
              <Button onClick={handleOpen} variant='contained' style={{ position: 'fixed', right: 65, zIndex: 1, borderRadius: 8, height: 40, top: 100 }} size="large">
                <Upload /> <Typography style={{ textTransform: 'none' }}>Upload Photos</Typography>
              </Button>
            )}

            


          </div> */}
        </div>
      </div>
      {/* </Box> */}

      {/* Content area with conditional rendering based on userType and selected tab */}
      <Box style={{ zIndex: 0 }}>
      <TabPanel value={values} index={0}>
      
          {selectedUrl?.length === 0 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "200px",
                  border: "2px solid #337AB7",
                  borderRadius: "8px",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "60%",
                  margin: "0 auto",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    color: "#333",
                    margin: 0,
                  }}
                >
                  No photos were found please upload new photos in the album.
                </p>
              </div>
            </div>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                  minHeight: "100vh",
                  gap: 0.05,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    minHeight: "100vh",
                    gap: 0.05,
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: isMobileOrTablet
                        ? "repeat(2, 1fr)"
                        : "repeat(4, 1fr)",
                      gap: "7px",
                    }}
                  >
                    {/* Loop through selectedUrl to display images */}
                    {selectedUrl?.slice(
                      (currentPage - 1) * ITEMS_PER_PAGE,
                      currentPage * ITEMS_PER_PAGE
                    ).map((item, index) => (
                      // <Box
                      //   key={item._id}
                      //   sx={{
                      //     position: "relative",
                      //     cursor: "pointer",
                      //     borderRadius: 2,
                      //     height:"auto",
                      //     overflow: "hidden",
                      //     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      //   }}
                      // >
                      //   {/* Checkbox for selecting images */}


                      //   <span
                      //     style={{
                      //       position: "absolute",
                      //       top: 5,
                      //       left: 5,
                      //       backgroundColor: "rgba(0, 0, 0, 0.5)",
                      //       color: "white",
                      //       padding: "2px 5px",
                      //       borderRadius: "3px",
                      //       zIndex: 10,
                      //       fontSize: "14px",
                      //     }}
                      //   >
                      //     {index + 1 + (currentPage - 1) * ITEMS_PER_PAGE}
                      //   </span>

                      //   {/* Image preview */}
                      //   <img
                      //     src={item?.Images?.lowResolution}
                      //     alt={`Image ${index + 1}`}
                      //     style={{
                      //       width: "100%",
                      //       // height: "200px",
                      //       objectFit: "cover",
                      //       borderRadius: 8,
                      //     }}
                      //   />
                      // </Box>

                      <div className="pics" key={index}>
                                  <Box
                                    onClick={() =>
                                      openGallery(
                                        item.Images.highResolution,
                                        item?.event,
                                        item?.currentPage,
                                        item?.fileName
                                      )
                                    }
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        width: "100%",
                                        height: "auto",
                                      }}
                                    >
                                      {" "}
                                      <span
                                        style={{
                                          position: "absolute",
                                          top: 5,
                                          left: 5,
                                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                                          color: "white",
                                          padding: "2px 5px",
                                          borderRadius: "3px",
                                        }}
                                      >
                                                                        {index + 1 + (currentPage - 1) * ITEMS_PER_PAGE}
                                      </span>
                                      <img
                                        src={item.Images.lowResolution}
                                        style={{
                                          width: "100%",
                                          height: "200px",
                                          objectFit: "cover", // Maintain aspect ratio and cover the area
                                          borderRadius: 8,
                                        }}
                                      />
                                    </div>
                                  </Box>
                                </div>
                    ))}
                  </Box>



                </Box>

              </Box>
              {/* {selectedUrl.length > 0 && ( // Check if there are photos to paginate */}
             
              {/* )} */}
              {loaderScroll && (
                <div
                  style={{
                    zIndex: 9999,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              )}
            </>
          )}
   
        </TabPanel>
        <TabPanel value={values} index={1}>
          {/* Conditional rendering based on userType */}
          {userType === "photographer" &&
            (selectedUrl?.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                  backgroundborder: "2px solid #337AB7",
                  borderborderRadius: "8px",
                  cornerspadding: "20px",
                  contentboxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  shadowwidth: "60%",
                  widthmargin: "0 auto",
                }}
              >
                {" "}
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    color: "#333",
                    margin: 0,
                  }}
                >
                  {" "}
                  No matching photos were found for this user. Please select
                  another user or add a new user whose face you want to be
                  recognized in the album.
                </p>{" "}
              </div>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center", // Center horizontally
                    justifyContent: "center", // Center vertically
                    width: "100%", // Full width
                    minHeight: "100vh", // Full height of the viewport
                    gap: 0.05,
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: isMobileOrTablet
                        ? "repeat(2, 1fr)"
                        : "repeat(4, 1fr)",
                      gap: "7px",
                    }}
                  >
                    {selectedUrl?.map((item, index) => (
                      <Box
                        key={index}
                        className="pics"
                        sx={{
                          breakInside: "avoid",
                          // marginBottom: '1px',
                        }}
                        onClick={() =>
                          openGallery(
                            item?.Images?.highResolution,
                            item?.event,
                            item?.currentPage,
                            item?.fileName
                          )
                        }
                      >
                        {" "}
                        <span
                          style={{
                            position: "absolute",
                            top: 5,
                            left: 5,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                            padding: "2px 5px",
                            borderRadius: "3px",
                          }}
                        >
                                                          {index + 1 + (currentPage - 1) * ITEMS_PER_PAGE}
                        </span>
                        <img
                          src={item?.Images?.lowResolution}
                          style={{
                            width: "100%",
                            height: "200px",
                            borderRadius: 8,
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>

                {loaderScroll && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress color="inherit" />
                  </div>
                )}
              </>
            ))}
          {/* Conditional rendering for user */}

          {userType === "user" && (
            <>
              {adduserLoad ? (
                <AvatarLoader />
              ) : (
                <>
                  {startDetect ? (
                    <>
                      {finalImagesLoader ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {/* <CircularProgress color="inherit" /> */}
                          <AvatarDetectLoader
                            userName={selectedAvatar.name}
                            image={selectedAvatar.imageUrl}
                          />
                        </div>
                      ) : finaldetectedImages?.length === 0 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            justifyItems: "center",
                            alignContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "60%",
                              minHeight: "100px",
                              alignItems: "center",
                              backgroundColor: "#F0F4FF",
                              backgroundborder: "2px solid #337AB7",
                              borderborderRadius: "8px",
                              cornerspadding: "20px",
                              contentboxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              shadowwidth: "60%",
                              widthmargin: "0 auto",
                              margin: "40px",
                              padding: "10px",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "18px",
                                color: "#333",
                                margin: 0,
                              }}
                            >
                              No matching photos were found for this user.
                              Please select another user or add a new user whose
                              face you want to search in the album.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center", // Center horizontally
                              justifyContent: "flex-start", // Align to the top vertically
                              width: "100%", // Full width
                              minHeight: "100vh", // Full height of the viewport
                              gap: 0.05,
                            }}
                          >
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: isMobileOrTablet
                                  ? "repeat(2, 1fr)"
                                  : "repeat(4, 1fr)",
                                gap: "7px",
                              }}
                            >
                              {finaldetectedImages?.map((item, index) => (
                                <div className="pics" key={index}>
                                  <Box
                                    onClick={() =>
                                      openGallery(
                                        item.Images.highResolution,
                                        item?.event,
                                        item?.currentPage,
                                        item?.fileName
                                      )
                                    }
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        width: "100%",
                                        height: "auto",
                                      }}
                                    >
                                      {" "}
                                      <span
                                        style={{
                                          position: "absolute",
                                          top: 5,
                                          left: 5,
                                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                                          color: "white",
                                          padding: "2px 5px",
                                          borderRadius: "3px",
                                        }}
                                      >
                                {index + 1 + (currentPage - 1) * ITEMS_PER_PAGE}
                                      </span>
                                      <img
                                        src={item.Images.lowResolution}
                                        style={{
                                          width: "100%",
                                          height: "200px",
                                          objectFit: "cover", // Maintain aspect ratio and cover the area
                                          borderRadius: 8,
                                        }}
                                      />
                                    </div>
                                  </Box>
                                </div>
                              ))}
                            </div>
                            {finalPage != "" ? (
                              <>
                                {finalPage <= currentPage ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      width: "100%",
                                      minHeight: "100px",
                                      alignItems: "center",
                                      backgroundColor: "#F0F4FF",
                                      backgroundborder: "2px solid #337AB7",
                                      borderborderRadius: "8px",
                                      cornerspadding: "20px",
                                      contentboxShadow:
                                        "0 4px 8px rgba(0, 0, 0, 0.1)",
                                      shadowwidth: "60%",
                                      widthmargin: "0 auto",
                                      margin: "40px",
                                      padding: "10px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        textAlign: "center",
                                        fontSize: "18px",
                                        color: "#333",
                                        margin: 0,
                                      }}
                                    >
                                      Successfully retrieved all detected photos
                                      in this album
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      width: "100%",
                                      minHeight: "100px",
                                      alignItems: "center",
                                      backgroundColor: "#F0F4FF",
                                      backgroundborder: "2px solid #337AB7",
                                      borderborderRadius: "8px",
                                      cornerspadding: "20px",
                                      contentboxShadow:
                                        "0 4px 8px rgba(0, 0, 0, 0.1)",
                                      shadowwidth: "60%",
                                      widthmargin: "0 auto",
                                      margin: "40px",
                                      padding: "10px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        textAlign: "center",
                                        fontSize: "18px",
                                        color: "#333",
                                        margin: 0,
                                      }}
                                    >
                                      Go to the next page to view more photos
                                    </p>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    minHeight: "100px",
                                    alignItems: "center",
                                    backgroundColor: "#F0F4FF",
                                    backgroundborder: "2px solid #337AB7",
                                    borderborderRadius: "8px",
                                    cornerspadding: "20px",
                                    contentboxShadow:
                                      "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    shadowwidth: "60%",
                                    widthmargin: "0 auto",
                                    margin: "40px",
                                    padding: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      color: "#333",
                                      margin: 0,
                                    }}
                                  >
                                      Go to the next page to view more photos
                                      </p>
                                </div>
                              </>
                            )}
                          </Box>
                          {/* Loader conditionally rendered */}
                          {loaderScroll && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress color="inherit" />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Avrcard
                      avatars={userData}
                      selectedAvatar={selectedAvatar}
                      onAvatarSelect={handleAvatarSelect}
                      searchDetaect={detectImages}
                    />
                  )}
                </>
              )}
            </>
          )}
        </TabPanel>

        {/* Additional tab panel for photographer */}
        {userType === "photographer" && (
          <TabPanel value={values} index={1}>
            {ownPhoto?.length === 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <img src={noImage} style={{ width: 200, height: 200 }} /> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                    backgroundborder: "2px solid #337AB7",
                    borderborderRadius: "8px",
                    cornerspadding: "20px",
                    contentboxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    shadowwidth: "60%",
                    widthmargin: "0 auto",
                  }}
                >
                  {" "}
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      color: "#333",
                      margin: 0,
                    }}
                  >
                    {" "}
                    No matching photos were found for this user. Please select
                    another user. You can also add a new user whose face you
                    want to be searched in this album.
                  </p>{" "}
                </div>
              </div>
            ) : (
              <>
                {/* Render photos uploaded by photographer */}
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.05 }}>
                  <div
                    style={{
                      WebkitColumnCount: 4,
                      MozColumnCount: 4,
                      columnCount: 4,
                      WebkitColumnWidth: "33%",
                      MozColumnWidth: "33%",
                      columnWidth: "33%",
                      columnGap: "7px",
                    }}
                  >
                    {ownPhoto?.map((item, index) => (
                      <div className="pics" key={index}>
                        <Box
                          onClick={() =>
                            viewGallerys(
                              item?.Images?.lowResolution,
                              item?.event,
                              item?.currentPage,
                              item?.fileName,
                              item
                            )
                          }
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "auto",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                top: 5,
                                left: 5,
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                                padding: "2px 5px",
                                borderRadius: "3px",
                              }}
                            >
                          {index + 1 + (currentPage - 1) * ITEMS_PER_PAGE}
                          </span>
                            <img
                              src={item?.Images?.lowResolution}
                              style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: 8,
                              }}
                            />
                          </div>
                        </Box>
                      </div>
                    ))}
                  </div>
                </Box>
                {/* Loader conditionally rendered */}
                {loaderScroll && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress color="inherit" />
                  </div>
                )}
              </>
            )}
          </TabPanel>
        )}
         <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                {/* <Pagination
                  count={totalPage}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                  variant="outlined"
                  shape="rounded"
                /> */}
                <Box>
            <Button onClick={handlePreviousPage} disabled={currentPage === 1 || loader}>
                Previous
            </Button>
            <span>Page {currentPage} of {totalPage}</span>
            <Button onClick={handleNextPage} disabled={currentPage === totalPage || loader}>
                Next
            </Button>
        </Box>
      </Box>
      </Box>

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ borderRadius: 5, zIndex: 500 }}
      >
        <Box sx={{ width: "100%", ...style }}>
          <Stack
            style={{
              display: "flex",
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
              <CloseCircleTwoTone />
            </Box>
          </Stack>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {status === 401 ? (
              <SubscriptionPay
                subscriptionStatus={subscriptionStatus}
                subscriptionMessage={subscriptionMessage}
              />
            ) : null}
          </Box>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <CircularProgress color="inherit"  />  */}
          </div>

          {errorMessage ? (
            <Alert severity="error">
              <Typography>{errorMessage}</Typography>
            </Alert>
          ) : null}

          <div style={{ display: "flex", justifyContent: "center" }}></div>

          {files?.length > 0 && openModal ? (
            <>
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="md"
                fullWidth
              >
                <DialogTitle>
                  <Typography variant="h6">Upload Photos</Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseCircleTwoTone />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Typography variant="body1">{`${files.length} Phoftos Selected`}</Typography>
                    <Typography variant="body2" align="right">
                      {`${(
                        files.reduce((acc, file) => acc + file.size, 0) /
                        1024 /
                        1024
                      ).toFixed(2)} MB`}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={totalProgress}
                    sx={{ mb: 2 }}
                  />
                  {files.map((file, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="body2">
                          {file.name} ({(file.size / 1024 / 1024).toFixed(2)}{" "}
                          MB)
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={uploadProgress[file.name] || 0}
                          sx={{ height: 5, borderRadius: 2 }}
                        />
                      </Box>
                      <Typography
                        variant="body2"
                        align="right"
                        sx={{ minWidth: 80 }}
                      >
                        {uploadProgress[file.name] === 100 ? "SUCCESS" : ""}
                      </Typography>
                    </Box>
                  ))}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleUpload}
                    variant="contained"
                    color="primary"
                    sx={{ marginLeft: 2 }}
                  >
                    Upload Photos
                  </Button>
                  <Button
                    onClick={() => setOpenModal(false)}
                    variant="outlined"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <>
              <div style={{ textAlign: "center", marginTop: 10 }}>
                <label
                  htmlFor="file-input-computer"
                  style={{
                    backgroundColor: "#eaf0fa",
                    color: "#1976D2",
                    padding: "10px 15px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    display: "inline-block",
                    width: "94.5%", // Adjusted to match the Button's width
                    border: "1px solid #007bff", // Outlined style
                  }}
                >
                  Upload Your Files
                </label>
                <FormHelperText>
                  Only formatted JPG and JPEG images
                </FormHelperText>
                <input
                  id="file-input-computer"
                  type="file"
                  onChange={handleFileChange}
                  multiple
                  accept=".jpg, .jpeg" // Added accept attribute
                  style={{ display: "none" }}
                />
              </div>
            </>
          )}

          {totalProgress === 100 && (
            <div>
              <p>Uploaded successfully: {totalProgress}%</p>
            </div>
          )}

          {totalProgress === 100 && (
            <div style={{ marginTop: 50 }}>
              <Button
                onClick={closeModal}
                variant="contained"
                style={{
                  marginTop: 50,
                  width: "30%",
                  position: "absolute",
                  bottom: 10,
                  right: 20,
                }}
              >
                confirm
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
