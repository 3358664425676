import { Clear, Password } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { addphotographer, updatePhotographerData } from "../../../API/Admin";

export default function AddPhotographer({
  responseStatus,
  handleEdit,
  handleC,
  editData,
}) {
  const [opens, setOpens] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [status, setStatus] = useState("");

  const [data, setData] = useState({
    name: "",
    email: "",
    number: "",
  });

  useEffect(() => {
    if (handleEdit == true) {
      handleOpen();
      setData(editData);
    }
  }, [handleEdit]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrorMessage("");
    setSuccessMessage("");
  };

  //console.log("data", data.name);

  const handleClose = () => {
    handleC(false);
    setOpens(false);
    setErrorMessage("");
    setSuccessMessage("");
    setStatus("");
    setData("");
  };
  const handleOpen = () => {
    setOpens(true);
  };

  const addPhotoGrapher = async () => {
    if (!data.name) {
      setErrorMessage("PhotoGrapher name required");
    } else if (!data.email) {
      setErrorMessage("PhotoGrapher email required");
    } else if (!data.number) {
      setErrorMessage("PhotoGrapher number required");
    } else {
      try {
        //console.log("number11111", data);
        let response;
        if (data?._id) {
          response = await updatePhotographerData(data?._id, data);
          setStatus(response?.status);
          //console.log("response?.statusresponse?.status",response);
          
          if (response?.message === "Photographer updated successfully") {
          responseStatus(response?.status);
          setSuccessMessage(response?.data?.Message);
       
          setTimeout(() => {
            handleClose();
          }, 1000);
        }

        } else {
          response = await addphotographer(data);
        }

        //console.log("response123", response);
        setStatus(response?.status);
        if (response?.status === 200) {
          responseStatus(response?.status);
          // setErrorMessage(response?.data?.Message)
          //console.log("responseData", response?.data?.Message);
          setSuccessMessage(response?.data?.Message);
          setTimeout(() => {
            handleClose();
          }, 1000);
        } else {
          setSuccessMessage(response?.response?.data?.Message);
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
  //console.log("Status", status, successMessage);

  return (
    <>
      <div style={{ display: "flex", position: "absolute", right: 10 }}>
        <Button variant="outlined" onClick={handleOpen}>
          <Typography style={{ textTransform: "none" }}>
            Add PhotoGrapher
          </Typography>
        </Button>
      </div>
      <div>
        <Modal
          open={opens}
           onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <Box onClick={handleClose}>
                <Clear />
              </Box>
            </div>
            {successMessage && (
              <Alert severity={status === 200 ? "success" : "error"}>
                {successMessage}
              </Alert>
            )}

            <Stack spacing={1} style={{ marginBottom: "6px" }}>
              <Typography style={{ fontSize: "18px", color: "black" }}>
                Name *
              </Typography>
              <TextField
                placeholder="Enter Photographer Name"
                value={data.name}
                onChange={handleChange}
                style={{ width: "100%" }}
                name="name"
              />
              {errorMessage === "Photographer name required" && (
                <Typography
                  style={{ color: "red", fontSize: "16px", textAlign: "left" }}
                >
                  {errorMessage}
                </Typography>
              )}
            </Stack>
            <Stack spacing={1} style={{ marginBottom: "6px" }}>
              <Typography style={{ fontSize: "18px", color: "black" }}>
                Email *
              </Typography>
              <TextField
                placeholder="Enter Photographer email"
                value={data.email}
                onChange={handleChange}
                style={{ width: "100%" }}
                name="email"
              />
              {errorMessage === "Photographer email required" && (
                <Typography
                  style={{ color: "red", fontSize: "16px", textAlign: "left" }}
                >
                  {errorMessage}
                </Typography>
              )}
            </Stack>
            <Stack spacing={1} style={{ marginBottom: "6px" }}>
              <Typography style={{ fontSize: "18px", color: "black" }}>
                Number *
              </Typography>
              <TextField
                placeholder="Enter Photographer Number"
                value={data.number}
                onChange={handleChange}
                style={{ width: "100%" }}
                name="number"
              />
              {errorMessage === "Photographer number required" && (
                <Typography
                  style={{ color: "red", fontSize: "16px", textAlign: "left" }}
                >
                  {errorMessage}
                </Typography>
              )}
            </Stack>
            <Button
              variant="contained"
              fullWidth
              style={{ marginTop: 20 }}
              onClick={addPhotoGrapher}
            >
              Submit
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
}
