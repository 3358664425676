import baseApi from "./baseApi";

export const addSubscription=async(data)=>{

    const AccesToken = localStorage.getItem("accessToken");
     try {
       let config = {
          headers: {
             'Authorization': `Bearer ${AccesToken}`,
          }
        };
      
      const response= await baseApi.post(`/admin/AddSubscription`,data,config)
      return response
     } catch (error) {
      return error
     }
  }


  export const updateSubscription=async(subscriptionId,data)=>{

    //console.log("subscriptionId,data",subscriptionId,data)
   
    const AccesToken = localStorage.getItem("accessToken");
     try {
       let config = {
          headers: {
             'Authorization': `Bearer ${AccesToken}`,
          }
        };
      
      const response= await baseApi.patch(`/admin/updateSubscription/${subscriptionId}`,data,config)
      return response
     } catch (error) {
      return error
     }
  }

  export const deleteSubscription=async(userType,subscriptionId)=>{
     //console.log("userType,subscriptionId",userType,subscriptionId)
    const AccesToken = localStorage.getItem("accessToken");
     try {
       let config = {
          headers: {
             'Authorization': `Bearer ${AccesToken}`,
          }
        };
      
      const response= await baseApi.delete(`/admin/deleteSubscription/${userType}/${subscriptionId}`,config)
      return response
     } catch (error) {
      return error
     }
  }


  export const getSubscription=async(user)=>{
   
    const AccesToken = localStorage.getItem("accessToken");
     try {
       let config = {
          headers: {
             'Authorization': `Bearer ${AccesToken}`,
          }
        };

      
      const response= await baseApi.get(`/admin/getSubscriptions?user=${user}`,config)
      return response
     } catch (error) {
      return error
     }
  }

  export const getDailyReport=async()=>{
    //console.log("Test")
   const AccesToken = localStorage.getItem("accessToken");
    try {
      let config = {
         headers: {
            'Authorization': `Bearer ${AccesToken}`,
         }
       };

     
     const response= await baseApi.get(`/admin/dailyReport`,config)
     return response
    } catch (error) {
     return error
    }
 }


 export const getphotographerList=async(data)=>{
   //console.log("data",data)

  const AccesToken = localStorage.getItem("accessToken");
   try {
     let config = {
        headers: {
           'Authorization': `Bearer ${AccesToken}`,
        }
      };

    const response= await baseApi.get(`/admin/getaddedPhotoGrapher?name=${data.searchData}&page=${data.page}`,config)
    return response
   } catch (error) {
    return error
   }
}

export const getEventListApi=async(id)=>{
   //console.log("id",id)

   const AccesToken = localStorage.getItem("accessToken");
    try {
      let config = {
         headers: {
            'Authorization': `Bearer ${AccesToken}`,
         }
       };
 
     
     const response= await baseApi.get(`/admin/getEventList?id=${id}`,config)
     return response
    } catch (error) {
     return error
    }
 }

 export const DeletePhotographerApi=async(id)=>{
   //console.log("id",id)

   const AccesToken = localStorage.getItem("accessToken");
    try {
      let config = {
         headers: {
            'Authorization': `Bearer ${AccesToken}`,
         }
       };
 
     
     const response= await baseApi.delete(`/admin/deletePhotographer?id=${id}`,config)
     return response
    } catch (error) {
     return error
    }
 }

 export const getAllEvenetApi=async(data)=>{

   const AccesToken = localStorage.getItem("accessToken");
    try {
      let config = {
         headers: {
            'Authorization': `Bearer ${AccesToken}`,
         }
       };
 
     
     const response= await baseApi.get(`/admin/getAllEventList?name=${data.searchData}&page=${data.page}`,config)
     return response
    } catch (error) {
     return error
    }
 }


 export const sendEmailForDetectedFaces=async()=>{

   const AccesToken = localStorage.getItem("accessToken");
    try {
      let config = {
         headers: {
            'Authorization': `Bearer ${AccesToken}`,
         }
       };
     
     const response= await baseApi.post(`http://localhost:5200/divine/Auth/FaceMatch`,config)
     return response
    } catch (error) {
     return error
    }
 }

 export const addphotographer=async(data)=>{
   //console.log("data",data)

  const AccesToken = localStorage.getItem("accessToken");
   try {
     let config = {
        headers: {
           'Authorization': `Bearer ${AccesToken}`,
        }
      };

    
    const response= await baseApi.post(`/admin/addPhotographer`,data,config)
    return response
   } catch (error) {
    return error
   }
}



export const Photographerdelete = async (id) => {
   //console.log("id", id);

   const AccessToken = localStorage.getItem("accessToken");
   try {
       const config = {
           headers: {
               'Authorization': `Bearer ${AccessToken}`,
           }
       };
       // /divine/admin/photograperdelete
       const response = await baseApi.delete(`/admin/photograperdelete/${id}`, config);
       return response;
   } catch (error) {
       console.error("Error deleting photographer:", error);
       throw error; 
   }
};




export const updatePhotographerData = async (id, data) => {
   //console.log(id, data, 'updateUserProfile');
   const accessToken = localStorage.getItem("accessToken");
 
   if (!accessToken) {
     //console.log("No access token found");
     return;
   }
 
   try {
     let config = {
       headers: {
         'Authorization': `Bearer ${accessToken}`,
       }
     };
     const response = await baseApi.patch(`/admin/editPhotographer/${id}`, data, config);
     //console.log(response, 'updatePhotographer response');
     return response.data;
   } catch (err) {
     //console.log("error", err);
     return err.response ? err.response.data : { message: "An unknown error occurred" };
   }
 };
