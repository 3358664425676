import apiInstance from "./baseApi";

export const feedBack = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      throw new Error("No access token found");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await apiInstance.post("/admin/sendFeedback", data, config);
    return response;
  } catch (err) {
    console.error("Error sending feedback:", err);
    throw err; 
  }
};
