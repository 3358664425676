
import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, TextField, Divider, Card, Stack ,Alert,Grid,useTheme, useMediaQuery} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { addSubscription, deleteSubscription, getSubscription, updateSubscription } from '../../API/Admin';

export default function CreatePackage() {
 const [open, setOpen] = useState(false);
 const [openDelete, setOpenDelete] = useState(false);
 const [errorMessage, setErrorMessage] = useState(false);
 const [subscriptionList, setSubscriptionList] = useState([]);
 const [status, setStatus] = useState('');
 const [isEditdata, setIsEditeddata] = useState(false);
 const [subscriptionId, setSubscriptionId] = useState('');
 const theme = useTheme();
 const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
 const [createData, setCreateData] = useState({
    planName: "",
    planPrice: "",
    planLimit: ""
 });

 const userType=localStorage.getItem("userType")
 //console.log("userType",userType)
 const handleChange = (e) => {
  const { name, value } = e.target;
  if(name==="planPrice"|| name==="planLimit"){
    const digitsOnly = value.replace(/\D/g, '');
    setCreateData({
      ...createData,
      [name]: digitsOnly
    });
  }
  else{
 
    setCreateData({
      ...createData,
      [name]: value
    });
  }
    
 };

 const handleClose = () => {
  setCreateData("")
  setErrorMessage("")
  setIsEditeddata("")
  setOpen(false);
    
   
 };

 const handleOpen = (status,item) => {
    if(status===true){
      setCreateData({
        planName: item?.name,
        planPrice: item?.price,
        planLimit: item?.upload
      })
}
setSubscriptionId(item?._id)
     setOpen(true);
 };

 const handleCloseDelete = () => {
    setOpenDelete(false);
 };

 const handleOpenDelete = () => {
    setOpenDelete(true);
 };

 const handlePayment = () => {
    // Implement payment logic here
 };




 const handleEventSubmit = async() => {
  if(!createData.planName){
    setErrorMessage("Subscription plan name is required")
  }
  else if(!createData.planPrice){
    setErrorMessage("Subscription price is required")
  }
  else if(!createData.planLimit){
    setErrorMessage("Subscription image limit is required")
  }
  else{
    try {
      const data={
        name:createData.planName,
        userType:userType,
        user:'photographer',
        price:createData.planPrice,
        description:"Test",
        upload:createData.planLimit

      }
      //console.log("data",data)
      if(isEditdata){
        const response= await updateSubscription(subscriptionId,data)
        //console.log("responseEdit",response)
        setStatus(response?.status)
          setErrorMessage(response?.data?.message)
          getsubscriptionPlan()
          setTimeout(()=>{
             handleClose()
          },2000)
      }
      else{
          const response= await addSubscription(data)
          //console.log("response",response)
          setStatus(response?.status)
          setErrorMessage(response?.data?.message)
          getsubscriptionPlan()
          setTimeout(()=>{
             handleClose()
          },2000)
      }

     
     } catch (error) {
       //console.log(error)
     }
  }

 };

 const getsubscriptionPlan=async()=>{
  const user="photographer"
      try {
        const response=await getSubscription(user)
        //console.log("response",response)
        setSubscriptionList(response?.data?.data)
      } catch (error) {
        //console.log(error)
      }
   }


   const deletesubscriptionPlan=async()=>{
   
        try {
          const response=await deleteSubscription(userType,subscriptionId)
          //console.log("response",response)
          if(response?.status===200){
            getsubscriptionPlan()
            handleCloseDelete()
          }
       
        } catch (error) {
          //console.log(error)
        }
     }

 useEffect(()=>{
  getsubscriptionPlan()
 },[])

 const style = {
     position: 'absolute',
    top: isMobileOrTablet?"55%":'58%',
    left: isMobileOrTablet?'18%' :"50%",
    transform: 'translate(-50%, -50%)',
    width: isMobileOrTablet ?300: 1000,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  
     minHeight: '70vh',
     overflow: 'auto', 
		  maxHeight: '76vh',
    marginLeft:15
 };

 
 const styles= {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    };
 const styled = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    paddingBottom:5
 };

 return (
    <>
      <div>
        <Modal
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styled}>
            <Typography>Are you sure you want to delete this Plan?</Typography>
            <div style={{position:'absolute',right:20}}>
            <Button onClick={handleCloseDelete}>No</Button>
            <Button onClick={deletesubscriptionPlan}>Yes</Button>
            </div>
           
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
    

           <Box sx={styles}>
               {
                 errorMessage? <Alert severity={status === 200 ? "success" : "error"}>{errorMessage}</Alert>:null
               }
               <Stack spacing={1} style={{ marginBottom: '6px' }}>
               <Stack>
                <Typography>Subscription Plan Name *</Typography>
                <TextField placeholder='Enter subscription name' name="planName" value={createData.planName} onChange={handleChange}/>
                </Stack>

                <Stack>
                <Typography>Subscription Plan Price *</Typography>
                <TextField placeholder='Enter Plan Price'  name="planPrice" value={createData.planPrice}  onChange={handleChange}/>
                </Stack>
               
               <Stack>
                 <Typography>Images Upload limit *</Typography>
               <TextField placeholder='Enter Images Upload limit'  name="planLimit"  value={createData.planLimit} onChange={handleChange}/>      
                 </Stack>
                </Stack>
                 <Button variant='contained' fullWidth style={{ marginTop: 20 }} onClick={handleEventSubmit}>{isEditdata? "Update": "Create"} </Button >
              </Box>
        </Modal>
      </div>
        <div>
        <Box sx={style}>
          <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <Typography variant='h6'>Subscription Plans</Typography>
            <Button onClick={()=>handleOpen(false)} variant='outlined'>Create Plan</Button>
          </div>
          <Divider style={{ marginTop: 10 }} />
          <Grid container spacing={2} marginTop={1}>
            {subscriptionList?.map((item, index) => (
              <Grid item xs={12} sm={12} md={4} lg={4} key={item._id || index} >
                 <Card   key={item._id || index} elevation={3} style={{ padding: 20, width: 280, backgroundColor: "#DCDCDC" }} onClick={() => handlePayment(100, 500)}>
              <Typography variant="h6" component="h2" style={{ fontWeight: "bold" }}>
                 {item.name}
              </Typography>
              <Typography variant="h6" component="h2" style={{ fontWeight: "bold" }}>
                {item.price}{"/-"}
              </Typography>
              <Typography>
                You can upload {item.upload} images
              </Typography>
              
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                <Box>
                 <Edit fontSize='large' onClick={()=>{handleOpen(true,item);setIsEditeddata(true)}}/>
                </Box>
                <Box>
                 <Delete fontSize='large' onClick={()=>{handleOpenDelete();setSubscriptionId(item._id) }}/>
                </Box>
              </div>
            </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </>
 );
}


