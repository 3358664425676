
import './App.css';
import { BrowserRouter } from "react-router-dom";
import MainRoute from './Route';

function App() {
  return (
    <BrowserRouter>
    <MainRoute/>
       
    </BrowserRouter>
    // <div className="App">
    //   <UploadImages/>
    //   <ListOfImages/>
    //   <Login/>
    // </div>
  );
}

export default App;
