import React from 'react';
import Login from '../Screen/Auth/Login';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = () => {
  const location = useLocation(); // Access the current location

  return [
    { path: '/login', element: <Login /> },
    { path: '/', element: <Navigate to="/login" replace state={{ from: location }} /> },
  ];
};

export default PrivateRoute;
