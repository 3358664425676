import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { deleteImageApi, DownloadImage, getAllImages } from "../API/Photographer";
import { Modal, Stack, Box, IconButton, Button, CircularProgress } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Clear from '@mui/icons-material/Clear';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import FilterOutlinedIcon from '@mui/icons-material/FilterOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import '../css/view.css';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Delete } from "@mui/icons-material";

function DeleteImages({ deleteData, closeDeleteImage, deletedStatus, eventData }) {
  const location = useLocation();
  const eventDatas = deleteData;

  const [currentPage, setCurrentPage] = useState(eventDatas?.currentPage);
  const [event, setEvent] = useState(eventDatas.filename);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [activeImage, setActiveImage] = useState(eventDatas?.Url);
  const [images, setImages] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [imagesPerPage, setImagesPerPage] = useState(10);
  const [availImage, setAvailImage] = useState('');
  const [originalName, setOriginalName] = useState(eventDatas.originalName);
  const [loading, setLoading] = useState(false); // Add loader state

  const eventTab = location.state?.eventTab;
  const navigate = useNavigate();

  useEffect(() => {
    getAllCarouselImages();
  }, [currentPage]);

  const getAllCarouselImages = async () => {
    try {
      const status = "myPhotos";
      const response = await getAllImages(status, currentPage, event);
      const newImages = response?.data?.data?.uploads || [];
      setImages(newImages);
      setTotalPage(response.data.Pages.totalPage);
      setAvailImage(response.data.Pages.imagessofthePage);
    } catch (err) {
      console.error("Error fetching images:", err);
    }
  };

  const handlePrevious = async () => {
    console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhh");
    
    const currentIndex = images.findIndex((image) => image.fileUrl === activeImage);
    if (currentIndex > 0) {
      setActiveImage(images[currentIndex - 1].fileUrl);
      setOriginalName(images[currentIndex - 1].fileName);
    } else if (currentPage === 1 && currentIndex === 0) {
      setCurrentPage(totalPage);
      const response = await getAllImages("myPhotos", totalPage, event);
      const newImages = response?.data?.data?.uploads || [];
      setImages(newImages);
      setActiveImage(newImages[newImages.length - 1]?.fileUrl);
      setOriginalName(newImages[newImages.length - 1]?.fileName);
      setAvailImage(response.data.Pages.imagessofthePage);
    } else if (currentPage > 1 && currentIndex === 0) {
      setCurrentPage(currentPage - 1);
      const response = await getAllImages("myPhotos", currentPage - 1, event);
      const newImages = response?.data?.data?.uploads || [];
      setImages(newImages);
      setActiveImage(newImages[newImages.length - 1]?.fileUrl);
      setOriginalName(newImages[newImages.length - 1]?.fileName);
      setAvailImage(response.data.Pages.imagessofthePage);
    }
  };

  const handleForward = async () => {
    const currentIndex = images.findIndex((image) => image.fileUrl === activeImage);
    if (currentIndex < imagesPerPage - 1 && currentIndex < images.length - 1) {
      setActiveImage(images[currentIndex + 1].fileUrl);
      setOriginalName(images[currentIndex + 1]?.fileName);
    } else if (currentPage < totalPage) {
      setCurrentPage((prevPage) => prevPage + 1);
      const response = await getAllImages("myPhotos", currentPage + 1, event);
      const newImages = response?.data?.data?.uploads || [];
      setImages(newImages);
      setActiveImage(newImages[0]?.fileUrl);
      setOriginalName(newImages[0]?.fileName);
      setAvailImage(response.data.Pages.imagessofthePage);
    } else if (currentPage === totalPage) {
      setCurrentPage(1);
      const response = await getAllImages("myPhotos", 1, event);
      const newImages = response?.data?.data?.uploads || [];
      setImages(newImages);
      setActiveImage(newImages[0]?.fileUrl);
      setOriginalName(newImages[0]?.fileName);
      setAvailImage(response.data.Pages.imagessofthePage);
    }
  };

  const handleDelete = async () => {
    setLoading(true); // Start loader
    try {
      const response = await deleteImageApi(eventDatas);
      if (response.status === 200) {
        setLoading(false); // Stop loader when response is successful
        const responses = await getAllImages("myPhotos", totalPage, event);
        handleForward();
        setDeleteStatus(true);
        uploadImages(true);
      }
      getAllCarouselImages();
    } catch (err) {
      console.error(err);
    } finally {
      closeDeleteImage(false); // Close modal
    }
  };

  const uploadImages = (status) => {
    closeDeleteImage(false);
    // if (deleteStatus || status) {
    //   window.location.reload();
    // }
  };

  const handleDownload = async (datas) => {
    try {
      const urlss = datas.split('/');

      const lastTwoIndices = urlss.slice(-2);
      let uri = lastTwoIndices.join('/');
      //console.log("kkkkkkkkk", uri);
      let modifiedUri = uri;
      if (uri.includes('%20')) {
        modifiedUri = uri.replace(/%20/g, ' ');
      }
      //console.log("urlssurlss", modifiedUri);
      const data = {
        key: modifiedUri
      };
      //console.log('url1', datas);
      const res = await DownloadImage(data);
      //console.log("response", res);
      const fileName = 'download_divine_images';
      const imageData = new Uint8Array(res.data.Body.data);
      const contentType = res.ContentType;
      const imageBlob = new Blob([imageData.buffer], { type: "image/jpeg" });

      const url = window.URL.createObjectURL(imageBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      ////console.log(err);
    }
  };
  const isMobile = window.innerWidth <= 750;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    // <Modal open={true}>
    //   <div
    //     style={{
    //       position: "relative",
    //       backgroundColor: "rgba(251, 253, 255, 1)",
    //       height: "88.5vh",
    //       display: "flex",
    //       justifyContent: "space-between",
    //       alignItems: "center",
    //       marginTop: 65,
    //       marginInline: 5,
    //     }}
    //   >
    //     <Clear
    //       className="view-Cancel"
    //       sx={{ fontSize: "30px", color: "black" }}
    //       onClick={uploadImages}
    //     />

    //     <ArrowBackIosNewIcon
    //       onClick={handlePrevious}
    //       sx={{
    //         position: "absolute",
    //         left: 0,
    //         top: "50%",
    //         transform: "translateY(-50%)",
    //         color: "white",
    //       }}
    //     />

    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         height: "100%",
    //         width: "100%",
    //         justifyContent: "space-between",
    //       }}
    //     >
    //       <div
    //         style={{
    //           width: "110%",
    //           height: "100%",
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           backgroundColor: "rgba(251, 253, 255, 1)",
    //         }}
    //       >
    //         <TransformWrapper defaultScale={1} defaultPositionX={1} defaultPositionY={1}>
    //           {({ zoomIn, zoomOut }) => (
    //             <>
    //               <TransformComponent>
    //                 <img
    //                   src={activeImage}
    //                   alt="images"
    //                   style={{
    //                     maxWidth: "100%",
    //                     maxHeight: "85vh",
    //                   }}
    //                 />
    //               </TransformComponent>

    //               <div style={{
    //                 // position: "absolute",
    //                 // bottom: 50,
    //                 // right: 360,
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 alignItems: "center",
    //                 borderRadius: "5px",
    //                 padding: "10px",
    //               }}>
    //                 <button
    //                   style={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                     border: "none",
    //                     cursor: "pointer",
    //                     padding: "8px",
    //                     borderRadius: "5px",
    //                     marginBottom: "40px",
    //                   }}
    //                   onClick={() => zoomIn()}
    //                 >
    //                   <ZoomInOutlinedIcon sx={{ fontSize: "24px", color: "#007BFF" }} />
    //                 </button>

    //                 <button
    //                   style={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                     border: "none",
    //                     cursor: "pointer",
    //                     padding: "8px",
    //                     borderRadius: "5px",
    //                   }}
    //                   onClick={() => zoomOut()}
    //                 >
    //                   <ZoomOutOutlinedIcon sx={{ fontSize: "24px", color: "#007BFF" }} />
    //                 </button>
    //               </div>
    //             </>
    //           )}
    //         </TransformWrapper>
    //       </div>

    //       <div
    //         style={{
    //           width: "40%",
    //           marginTop: "20px",
    //           flexDirection: "column",
    //           justifyContent: "center",
    //           alignItems: "flex-start",
    //           padding: "20px",
    //           color: "black",
    //           backgroundColor: "#fff",
    //           margin: "20px",
    //         }}
    //       >
    //         <Stack direction="row" alignItems="center" spacing={2}>
    //           <FilterOutlinedIcon />
    //           <div>
    //             <h4 style={{ color: "black", marginTop: "20px" }}>Album Details</h4>
    //           </div>
    //         </Stack>

    //         {images.length > 0 && (
    //           <ul
    //             style={{
    //               listStyleType: "none",
    //               paddingLeft: 0,
    //               marginBottom: "20px",
    //             }}
    //           >
    //             {(() => {
    //               const currentImage = images.find(
    //                 (img) => img.Images.lowResolution === activeImage || img.Images.highResolution === activeImage
    //               );
    //               if (!currentImage) return null;

    //               return (
    //                 <>
    //                   <Box mb={2}>
    //                     <Stack direction="row" alignItems="center" spacing={2}>
    //                       <div
    //                         style={{
    //                           fontWeight: "bold",
    //                           fontSize: "24px",
    //                           color: "#007BFF",
    //                         }}
    //                       >
    //                         {eventData.name}
    //                       </div>
    //                       <div
    //                         style={{
    //                           fontWeight: "bold",
    //                           fontSize: "24px",
    //                           color: "#007BFF",
    //                         }}
    //                       >
    //                         |
    //                       </div>
    //                       <div
    //                         style={{
    //                           fontWeight: "bold",
    //                           fontSize: "24px",
    //                           color: "#007BFF",
    //                         }}
    //                       >
    //                         {eventData.venueAlias}
    //                       </div>
    //                     </Stack>
    //                   </Box>
    //                   <Box mb={2}>
    //                     <Stack direction="row" alignItems="center" spacing={2}>
    //                       <FmdGoodOutlinedIcon />
    //                       <div
    //                         style={{
    //                           fontWeight: "bold",
    //                           fontSize: "16px",
    //                         }}
    //                       >
    //                         {eventData.area}, {eventData.city}
    //                       </div>
    //                     </Stack>
    //                   </Box>
    //                   <Box mb={2}>
    //                     <Stack direction="row" alignItems="center" spacing={2}>
    //                       <WatchLaterOutlinedIcon />
    //                       <div
    //                         style={{
    //                           color: "gray",
    //                         }}
    //                       >
    //                         {formatDate(eventData.eventDate)}
    //                       </div>
    //                     </Stack>
    //                   </Box>
    //                 </>
    //               );
    //             })()}
    //           </ul>
    //         )}
    //         <div
    //           style={{
    //             position: "relative",
    //             bottom: -40,
    //             right: 0,
    //             display: "flex",
    //             alignItems: "center",
    //             padding: "10px",
    //             marginTop: "20px",
    //           }}
    //         >

    //           <button
    //             style={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "center",
    //               backgroundColor: "#007bff",
    //               border: "none",
    //               borderRadius: "5px",
    //               cursor: "pointer",
    //               padding: "10px 20px",
    //               boxSizing: "border-box",
    //               color: "#fff",
    //               fontWeight: "bold",
    //               marginLeft: "20px",
    //             }}
    //             onClick={() => handleDownload(activeImage)}
    //           >
    //             <SystemUpdateAltOutlinedIcon sx={{ fontSize: "24px", color: "#fff" }} />
    //             <span style={{ marginLeft: "10px" }}>Download Image</span>
    //           </button>
    //         </div>
    //       </div>
    //     </div>

    //     {/* <ArrowForwardIosIcon
    //       onClick={handleForward}
    //       sx={{
    //         position: "absolute",
    //         right: 0,
    //         top: "50%",
    //         transform: "translateY(-50%)",
    //         color: "white",
    //       }}
    //     /> */}
    //   </div>
    // </Modal>
    <Modal open={true}>


      <div
        style={{
          position: "relative",
          backgroundColor: "rgba(251, 253, 255, 1)",
          height: "100vh",

          maxwidth: "100vw",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: isMobile ? 0 : 65,
          marginInline: 5,
          flexDirection: isMobile ? "column" : "row",
          overflowY: isMobile ? "auto" : "hidden",
        }}
      >

        <Clear
          className="view-Cancel"
          sx={{ fontSize: "30px", color: "black" }}
          onClick={uploadImages}
        />

        <ArrowBackIosNewIcon
          onClick={handlePrevious}
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            color: "white",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            height: "90%",
            width: isMobile ? "70%" : "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: isMobile ? "100%" : "60%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(251, 253, 255, 1)",
              position: "relative",
            }}
          >
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={1}
              defaultPositionY={1}
            >
              {({ zoomIn, zoomOut }) => (
                <>
                  <TransformComponent>
                    <img
                      src={activeImage}
                      alt="images"
                      style={{
                        maxWidth: "100%",
                        maxHeight: isMobile ? "70vh" : "90vh",
                      }}
                    />
                  </TransformComponent>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "5px",
                      padding: "10px",
                      position: isMobile ? "absolute" : "relative",
                      bottom: isMobile ? "-50px" : "auto",
                      left: isMobile ? "50px" : "auto",
                      right: isMobile ? "auto" : "0px",
                      top: isMobile ? "auto" : "10px",
                    }}
                  >
                    <button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                        cursor: "pointer",
                        padding: "8px",
                        borderRadius: "5px",
                        marginBottom: isMobile ? "0" : "0",
                        marginRight: isMobile ? "10px" : "20px",
                        fontSize: isMobile ? "18px" : "24px",
                      }}
                      onClick={() => zoomIn()}
                    >
                      <ZoomInOutlinedIcon
                        sx={{ fontSize: isMobile ? "18px" : "24px", color: "#007BFF" }}
                      />
                    </button>

                    <button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                        cursor: "pointer",
                        padding: "8px",
                        borderRadius: "5px",
                        fontSize: isMobile ? "18px" : "24px",
                      }}
                      onClick={() => zoomOut()}
                    >
                      <ZoomOutOutlinedIcon
                        sx={{ fontSize: isMobile ? "18px" : "24px", color: "#007BFF" }}
                      />
                    </button>
                  </div>
                </>
              )}
            </TransformWrapper>

          </div>

          <div
            style={{
              width: isMobile ? "100%" : "40%",
              marginTop: isMobile ? "10px" : "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: "20px",
              color: "black",
              backgroundColor: "#fff",
              margin: isMobile ? "0" : "20px",
            }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <FilterOutlinedIcon />
              <div>
                <h4 style={{ color: "black", marginTop: "20px" }}>
                  Album Details
                </h4>
              </div>
            </Stack>

            {images.length > 0 && (
              <ul
                style={{
                  listStyleType: "none",
                  paddingLeft: 0,
                  marginBottom: "20px",
                }}
              >
                {(() => {
                  const currentImage = images.find(
                    (img) => img.Images.lowResolution === activeImage || img.Images.highResolution === activeImage
                  );
                  if (!currentImage) return null;

                  return (
                    <>
                      <Box mb={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: isMobile ? "16px" : "19px",
                              color: "#007BFF",
                            }}
                          >
                            {eventData.name}
                          </div>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: isMobile ? "16px" : "19px",
                              color: "#007BFF",
                            }}
                          >
                            |
                          </div>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: isMobile ? "16px" : "19px",
                              color: "#007BFF",
                            }}
                          >
                            {eventData.venueAlias}
                          </div>
                        </Stack>
                      </Box>
                      <Box mb={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <FmdGoodOutlinedIcon />
                          <div
                            style={{
                              fontWeight: 400,
                              fontSize: isMobile ? "12px" : "14px",
                              color: "#425466",
                            }}
                          >
                            {eventData.area}, {eventData.city}
                          </div>
                        </Stack>
                      </Box>
                      <Box mb={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <WatchLaterOutlinedIcon />
                          <div
                            style={{
                              fontSize: isMobile ? "12px" : "14px",
                              fontWeight: 400,
                              color: "#425466",
                            }}
                          >
                            {formatDate(eventData.eventDate)}
                          </div>
                        </Stack>
                      </Box>
                    </>
                  );
                })()}
              </ul>
            )}
            <div
              style={{
                position: "relative",
                bottom: isMobile ? 0 : -40,
                right: 0,
                display: "flex",
                alignItems: "center",
                padding: "10px",
                marginTop: "20px",

              }}
            >
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#007bff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  padding: "10px 20px",
                  boxSizing: "border-box",
                  color: "#fff",
                  fontWeight: "bold",
                  margin: '5px'
                }}
                onClick={() => handleDownload(activeImage)}
              >
                <SystemUpdateAltOutlinedIcon
                  sx={{ fontSize: "24px", color: "#fff" }}
                />
                <span style={{ marginLeft: "10px" }}>Download Image</span>
              </button>
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "red",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  padding: "10px 20px",
                  boxSizing: "border-box",
                  color: "#fff",
                  fontWeight: "bold",
                  margin: '5px'

                }}
                onClick={() => handleDelete(activeImage)}
              >
Delete Image
                <Delete
                  sx={{ fontSize: "24px", color: "#fff" }}
                />
                {loading ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                    <span style={{ marginLeft: "10px" }}>Deleting....</span>
                    <CircularProgress width={"50px"} /> {/* Centered loader */}

                  </div>
                ) : (
                  <span style={{ marginLeft: "10px" }}></span>
                )}

              </button>
            </div>
          </div>
        </div>
      </div >
    </Modal >
  );
}

export default DeleteImages;
