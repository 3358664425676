

import { Card, CircularProgress, Stack, Typography, Grid, useMediaQuery ,useTheme, Button} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getDailyReport, sendEmailForDetectedFaces } from '../../API/Admin';
import Icon from 'react-icons-kit';
import {cloudUpload} from 'react-icons-kit/fa/cloudUpload'
import {cameraRetro} from 'react-icons-kit/fa/cameraRetro'
import {user} from 'react-icons-kit/fa/user'
import {book} from 'react-icons-kit/fa/book'


export default function DailyReport() {
    const [dailyData, setDailyData] = useState();

     const theme =useTheme()

    const mediaScreen=useMediaQuery(theme.breakpoints.up("md"))
    const dailyReports = async () => {
        try {
            const response = await getDailyReport();
            //console.log("Response",response)
            setDailyData(response?.data?.data);
        } catch (error) {
            //console.log("error", error);
        }
    };

    useEffect(() => {
        dailyReports();
    }, []);


 const sendNotificationFor =async()=>{
    try {
        const response= await sendEmailForDetectedFaces()
    } catch (error) {
        //console.log('error',error)
    }
 }

    return (
        <div>

       
        <Stack direction={"row"} style={{ marginTop: 10, display: 'flex', justifyContent: "center" }} gap={2}>
            {
                dailyData === undefined ?
                    <CircularProgress  color="inherit"/> :
                    <div >
                     <Typography>Photographer</Typography>
                     <div style={{marginTop:8}}>
                        <Grid container spacing={3}  >
                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
                                    <div  style={{display:'flex',justifyContent:"space-between" }}>
                                    <Stack direction={"column"}>
                                        <Typography variant='h6'>Yearly registration </Typography>
                                        <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerThisYearPhoto}</span></Typography>
                                    </Stack>
                                    <div style={{ color: '#800000' }}>
                                      <Icon size={30} icon={cameraRetro}/>
                                    </div>
                                   </div>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
                                    <div  style={{display:'flex',justifyContent:"space-between"}}>
                                    <Stack direction={"column"}>
                                        <Typography variant='h6'>Monthly registration </Typography>
                                        <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerThisMonthPhoto}</span></Typography>
                                    </Stack>
                                    <div style={{ color: '#800000' }}>
                                      <Icon size={30} icon={cameraRetro}/>
                                    </div>
                                   </div>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270,borderRadius:10 }} elevation={3}>
                                   
                                    <div  style={{display:'flex',justifyContent:"space-between"}}>
                                    <Stack direction={"column"}>
                                        <Typography variant='h6'>Today registration </Typography>
                                        <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerTodayPhoto}</span></Typography>
                                    </Stack>
                                    <div style={{ color: '#800000' }}>
                                      <Icon size={30} icon={cameraRetro}/>
                                    </div>
                                   </div>
                                </Card>

                            </Grid>

                          </Grid>

                        </div>  
                        <Typography style={{marginTop:15}}>User</Typography>
                        <div style={{marginTop:8}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
                                     <div  style={{display:'flex',justifyContent:"space-between"}}>
                                     <Stack direction={"column"}>
                                        <Typography variant='h6'>Yearly registration </Typography>
                                        <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerThisYearUser}</span></Typography>
                                    </Stack>
                                    <div style={{ color: '#F4A261' }}>
                                      <Icon size={30} icon={user}/>
                                    </div>
                                   </div>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
                                  
                                    <div  style={{display:'flex',justifyContent:"space-between"}}>
                                     <Stack direction={"column"}>
                                        <Typography variant='h6'>Monthly registration </Typography>
                                        <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerThisMonthUser}</span></Typography>
                                    </Stack>
                                    <div style={{ color: '#F4A261' }}>
                                      <Icon size={30} icon={user}/>
                                    </div>
                                   </div>
                                </Card>

                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270,borderRadius:10 }} elevation={3}>
                                    <div  style={{display:'flex',justifyContent:"space-between"}}>
                                     <Stack direction={"column"}>
                                        <Typography variant='h6'>Today registration</Typography>
                                        <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.registerTodayUser}</span></Typography>
                                    </Stack>
                                    <div style={{ color: '#F4A261' }}>
                                      <Icon size={30} icon={user}/>
                                    </div>
                                   </div>
                                </Card>

                            </Grid>

                        </Grid>
                       </div>
                        
                        <Typography style={{marginTop:15}}>Album</Typography>
                        <div style={{marginTop:8}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
                                    <div  style={{display:'flex',justifyContent:"space-between"}}>
                                        <Stack direction={"column"}>
                                        <Typography variant='h6'>Yearly create </Typography>
                                        <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.eventsCreatedThisYear}</span></Typography>
                                        </Stack>
                                    <div style={{ color: '#556B2F' }}>
                                      <Icon size={30} icon={book}/>
                                    </div>
                                   </div>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
                                    <div  style={{display:'flex',justifyContent:"space-between"}}>
                                         <Stack direction={"column"}>
                                        <Typography variant='h6'>Monthly create</Typography>
                                        <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.eventsCreatedThisMonth}</span></Typography>
                                       </Stack>
                                    <div style={{ color: '#556B2F' }}>
                                      <Icon size={30} icon={book}/>
                                    </div>
                                   </div>
                                </Card>

                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270,borderRadius:10 }} elevation={3}>
                                    <div  style={{display:'flex',justifyContent:"space-between"}}>
                                    <Stack direction={"column"}>
                                        <Typography variant='h6'>Today create </Typography>
                                        <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.eventsCreatedToday}</span></Typography>
                                    </Stack>
                                    <div style={{ color: '#556B2F' }}>
                                      <Icon size={30} icon={book}/>
                                    </div>
                                   </div>
                                </Card>

                            </Grid>

                        </Grid>

                        </div>
                        <Typography style={{marginTop:15}}>Upload</Typography>
                        <div style={{marginTop:8}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
                                  <div  style={{display:'flex',justifyContent:"space-between"}}>
                                    <Stack direction={"column"}>
                                        <Typography variant='h6'>Yearly upload </Typography>
                                        <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.uploadedImagesThisYear}</span></Typography>
                                    </Stack>
                                    <div style={{ color: '#778899' }}>
                                      <Icon size={30} icon={cloudUpload}/>
                                    </div>
                                   </div>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270 ,borderRadius:10}} elevation={3}>
                                    <div  style={{display:'flex',justifyContent:"space-between"}}>
                                            <Stack direction={"column"}>
                                                <Typography variant='h6'>Monthly upload </Typography>
                                                <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.uploadedImagesThisMonth}</span></Typography>
                                            </Stack>
                                    <div style={{ color: '#778899' }}>
                                      <Icon size={30} icon={cloudUpload}/>
                                    </div>
                                   </div>
                                </Card>

                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Card style={{ padding: 20, width: 270,borderRadius:10 }} elevation={3}>
                                    <div  style={{display:'flex',justifyContent:"space-between"}}>
                                      <Stack direction={"column"}>
                                        <Typography variant='h6'>Today upload </Typography>
                                        <Typography variant='h6'> <span style={{ fontWeight: "bold" }}>{dailyData?.uploadedImagesToday}</span></Typography>
                                      </Stack>
                                    <div style={{ color: '#778899' }}>
                                      <Icon size={30} icon={cloudUpload}/>
                                    </div>
                                   </div>
                                </Card>

                            </Grid>

                        </Grid>

                        </div>
                    </div>
            }
       
        </Stack>
        {/* <div style={{marginLeft:50,marginTop:10}}>
           <Typography>Send Notification for the detected faces in last 24 hours</Typography>
            <Button variant='outlined' onClick={sendNotificationFor} >Send Email</Button>
            </div> */}
        </div>

        
    );
}
