


import React, { useEffect, useState } from "react";
import {
  Typography,
  TableCell,
  Backdrop,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Stack,
  Modal,
  Box,
  Button,
  Card,
  Avatar
} from "@mui/material";
import { Group } from "@mui/icons-material";

import myImage from "../../../images/nodata.png";
import { SearchField } from "../../Component/SerachField";
import {
  TableCom,
  THead,
  TCell,
  TBody,
  Trow
} from "../../Component/table";
import { DeletePhotographerApi, getphotographerList } from "../../../API/Admin";
import Menus from "../../Component/menu";
import { useNavigate } from "react-router";

const UserDetailes = () => {
  const [photographerData, setPhotographerData] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [metaPage, setmetaPage] = useState(1);
  const [profile, setProfile] = useState('');
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const [page, setPage] = useState(1);
  const [skeliton, setskeliton] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [photographerId, setphotographerId] = useState('');
  const navigate = useNavigate();

  const getPotgrapherData = async () => {
    const userType = "user";
    const data = {
      userType,
      searchData,
      page
    };
    setskeliton(true);
    try {
      const response = await getphotographerList(data);
      //console.log(response, 'responnnnseeeeeeeeee');
      setPhotographerData(response?.data?.data);
      setmetaPage(response?.data?.pagination?.totalPages);
      //console.log("response", response);
      setskeliton(false);
    } catch (error) {
      //console.log("error", error);
    }
  };

  const useDebounce = (inputValue, delay) => {
    const [debounceValue, setDebounceValue] = useState(inputValue);
    useEffect(() => {
      const storvalue = setTimeout(() => {
        setDebounceValue(inputValue);
      }, delay);
      return () => {
        clearTimeout(storvalue);
      };
    }, [inputValue, delay]);
    return debounceValue;
  };

  const finalSearchValue = useDebounce(searchData, 1500);

  useEffect(() => {
    getPotgrapherData();
  }, [page, finalSearchValue]);

  const goToPreviousPage = () => {
    setPage(page - 1);
  };

  const goToNextPage = async () => {
    try {
      const nextPage = page + 1;
      setPage(nextPage);
    } catch (error) {
      // //console.log("error", error);
    }
  };

  const headers = [
    "Name",
    "Album Name",
    "Detected Photos",
    "Detected Date",
  ];

  

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };


  const calculateTotalCredits = (subscriptionHistory) => {
    let total = 0;
    subscriptionHistory?.forEach((item) => {
      total += item.creadits;
    });
    return total;
  };

  return (
    
    <div style={{ marginTop: 10, marginInline: isMobileOrTablet ? 20 : 5 }}>
        <Card style={{paddingBlock:"5px",}} elevation={2}>
            <Stack direction="row" spacing={2}>
            <Stack >
            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" sx={{ width: 56, height: 56 }} />
            </Stack>
            <Stack direction="row" >
                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" sx={{ width: 56, height: 56 }} />
                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" sx={{ width: 56, height: 56 }} />
                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" sx={{ width: 56, height: 56 }} />
             </Stack>
             </Stack>
        </Card>
      <>
        {skeliton ? (
          <div style={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress color="inherit" />
          </div>
        ) : photographerData?.length === 0 || photographerData === undefined ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={myImage} />
          </div>
        ) : (
          <>
            {/* <Stack width={350}>
              <SearchField searchProps={searchProps} placeholder="Search Name,Email" />
            </Stack> */}

            <div style={{ marginTop: 10 }}>
             

              <TableCom>
                <THead>
                  {headers?.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{
                        borderRight: "1px solid white",
                        paddingRight: "10px",
                        fontWeight: "40%",
                        fontSize: "100%",
                        color: "aliceblue",
                        backgroundColor: "#0C2142"
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </THead>

                <TBody>
                  {photographerData?.map((value, index) => (
                    <Trow key={index} >
                      
                      <TCell>
                        <Typography>{value.name ? value.name : "N/A"}</Typography>
                      </TCell>
                      <TCell>
                        <Typography>{value.email ? value.email : "N/A"}</Typography>
                      </TCell>
                      <TCell>
                        <Typography>{value.subscriptionLimit ? value.subscriptionLimit : "N/A"} /{calculateTotalCredits(value?.SubscriptionHistory)}</Typography>
                      </TCell>
                      <TCell>
                        <Typography>{value.createdAt ? value.createdAt.split('T')[0] : "N/A"}</Typography>
                      </TCell>
                    </Trow>
                  ))}
                </TBody>
              </TableCom>
            </div>

            <Stack direction={"row"} style={{ justifyContent: "flex-end", marginTop: 10 }} spacing={1}>
              <Typography style={{ marginTop: 8 }}>TotalPage:<span fontWeight={"bold"}> {metaPage}</span></Typography>
              <Button onClick={goToPreviousPage} disabled={page === 1} variant="outlined">Pre..</Button>
              <Typography fontWeight={"bold"} style={{ marginTop: 8 }}>{page}</Typography>
              <Button onClick={goToNextPage} disabled={page === metaPage} variant="outlined">Next</Button>
            </Stack>
          </>
        )}
      </>
    </div>
  );
};

export default UserDetailes;
