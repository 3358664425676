




import React, { useEffect, useState } from "react";

import Login1 from "../Auth/Login1";
import Login2 from "../Auth/Login2";
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import mountanImage from "../../images/vishal.png";
import Login4 from "../Auth/Login4";
import { Register, checKPhotoGrapherExist, otpSend, otpVerify } from "../../API/Auth";
import { json, useLocation, useNavigate, useNavigation } from "react-router";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { BackHand } from "@mui/icons-material";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { ic_keyboard_backspace_outline } from "react-icons-kit/md/ic_keyboard_backspace_outline";
import Icon from "react-icons-kit";
import AppointMentLogin from "../Auth/AppointmentLogin";
import { getphotographerList } from "../../API/Admin";
import { useDispatch } from "react-redux";
import { addRegisterData } from "../../Store/action";
import { uploadAndCheckFace } from "../../API/Photographer";
import { Link } from "react-router-dom";
import bgimage from "../../images/divine-pic-login.svg";
import sumeruLogo from "../../images/sumeru-logo.jpeg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Login() {


  const [showLogin, setShowLogin] = useState(true);

  const handleToggle = () => {
    setChecked(!checked); 
    setShowLogin(!showLogin); 
  };

  const location = useLocation();



  const [currentStep, setCurrentStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userData, setUserData] = useState("");
  const [appointMentPropdata, setAppointMentPropdata] = useState("");
  const [otp, setOtp] = useState(false);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("700"));
  const isMobile = useMediaQuery(theme.breakpoints.down("420"));
  const [successMessage, setSuccessMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [allPhotographer, setAllphotoGrapher] = useState([]);
  const dispatch = useDispatch();

  const leftPosition = isMobile ? "50px" : isMobileOrTablet ? "50px" : "112px";


  const [isOtpResending, setIsOtpResending] = useState(false); 
    const [resendTimer, setResendTimer] = useState(60); 
    const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCanResend(true); // Allow OTP resend after timer reaches 0
    }
  }, [resendTimer]);

  // const handleResendOtp = async () => {
  //   try {
  //     setIsOtpResending(true);
  //     setCanResend(false);
  //     setResendTimer(60); 

  //     const data = { email: userEmail };
  //     const response = await otpSend(data);
  //     if (response.status === 201) {
  //       setErrorMessage("");
  //       setSuccessMessage("OTP has been resent successfully.");
  //       setTimeout(() => {
  //         setSuccessMessage('');
  //       }, 5000);
  //     } else {
  //       setErrorMessage("Failed to resend OTP. Please try again.");
  //     }
  //   } catch (err) {
  //     setErrorMessage("Error occurred while resending OTP: " + err.message);
  //   } finally {
  //     setIsOtpResending(false);
  //   }
  // };


  const handleResendOtp = async () => {
    try {
        setIsOtpResending(true);
        setCanResend(false);
        setResendTimer(60); 
   
      setOtp(true);

        const data = { email: userEmail };
        const response = await otpSend(data);
   
        if (response.status === 201) {
          
     
            setErrorMessage("");
            setSuccessMessage("OTP has been resent successfully.");
            setTimeout(() => {
                setSuccessMessage('');
            }, 5000);
        } else {
            setErrorMessage("Failed to resend OTP. Please try again.");
            setTimeout(() => {
              setErrorMessage('');
          }, 5000);
        }
    } catch (err) {
        setErrorMessage("Error occurred while resending OTP: " + err.message);
    } finally {
        setIsOtpResending(false);
    }
};



  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const navigate = useNavigate("");


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
        event.preventDefault();
        if (currentStep === 3) { 
            // Submit profile if on the final step
            submitProfile();
        } else {
            // Otherwise, proceed to the next step
            nextStep();
        }
    }
};

useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
        document.removeEventListener("keydown", handleKeyDown);
    };
}, [currentStep, userEmail, userData, otp]); // Add any dependencies required


  const Login1Data = (data) => {
    setErrorMessage("");
    setUserEmail(data);
  };

  const Login2Data = (data) => {
    setErrorMessage("");
    setOtp(data);
  };

  const Login4Data = (data) => {
    setErrorMessage("");
    setSuccessMessage("");
    setUserData(data);
  };

  const EmailSubmit = async () => {
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userEmail) {
      setErrorMessage("Email is required");
    } else if (!emailFormat.test(userEmail)) {
      ////console.log("Invalid Email format");
      setErrorMessage("Invalid Email format");
    } else {
      try {
        const data = {
          email: userEmail,
        };
        const response = await otpSend(data);
        console.log("responseresponseqwr",response);
        
        if (response.status === 201) {
          setErrorMessage("");
          setCurrentStep(currentStep + 1);
        }else{

       if (response?.response?.data?.Message === 'Not allow to other than user') {  
          setErrorMessage("Not allow to other than user");
        } else { 
          setErrorMessage("An unexpected error occurred");
        }
      }

      } catch (err) {
        console.error(err);
        setErrorMessage(err.message);
      }
    }
  };

  const otpSubmit = async () => { };
  const photoGrapherList = (data) => {
    //console.log("photoGrapherList", data);
    setAllphotoGrapher(data);
  };

  // const submitProfile=async()=>{
  //   //console.log("UserDetailes",userData)
  //   try{
  //      if(userData?.file===null){
  //       setErrorMessage("Profile photo is required.");
  //     }
  //     // else if(userData.email=== "" || userData.email === undefined){
  //     //   setErrorMessage("Email is required");
  //     // }else if(!emailFormat.test(userData.email)){
  //     //   setErrorMessage("Email is invalid format");
  //     // }
  //     // else if(userData?.file === null){
  //     //   setErrorMessage("File is required");
  //     // }else if(userData?.file?.type != 'image/jpeg' && userData?.file?.type != 'image/png' && userData?.file?.type != 'image/jpg'){
  //     //   setErrorMessage("Select is valid format image");
  //     // }
  //     else{
  //       const formdata= new FormData();
  //       const userDeatail={
  //         name:appointMentPropdata.full_name,
  //         email: appointMentPropdata.email_id,
  //         // phone:userData.phone || "",
  //         userType:appointMentPropdata.user_role

  //       }
  //       //console.log("userDeataiqwertyl",userDeatail)
  //       formdata.append("userData",JSON.stringify(userDeatail));
  //       formdata.append("file",userData.file)
  //        const result = await Register(formdata);
  //        //console.log(result);
  //         //console.log("result",result)

  //       if(result.status === 200){

  //         let userType=""
  //         if(allPhotographer===true){
  //           userType="photographer"
  //         }
  //         else{
  //           userType=result?.data?.userType
  //         }
  //         const UserImage=result?.data.data?.imageUrl
  //         const UserId=result?.data.data?.AdditionalUsers[0]
  //         const datas={data:result?.data,userType,UserImage,UserId }

  //         dispatch(addRegisterData(datas))
  //         localStorage.clear();
  //         localStorage.setItem("accessToken",result?.data?.accessToken);
  //         localStorage.setItem("userType",result?.data?.userType);
  //         // localStorage.setItem("userType" , "photographer");

  //         localStorage.setItem("userId",result?.data.data?._id);
  //         localStorage.setItem("email",result?.data.data?.email);
  //         localStorage.setItem("profileImage",result?.data.data?.imageUrl);
  //         if(allPhotographer===true){
  //           //console.log("Passed")
  //           localStorage.setItem("userType","photographer");
  //          }
  //      else{
  //        //console.log("NotPassed")
  //         localStorage.setItem("userType", result?.data?.userType);
  //        // localStorage.setItem("userType", "admin");

  //      }
  //         setSuccessMessage("Profile Completed Successfully")
  //           setTimeout(()=>{
  //             setSuccessMessage('');
  //             navigate('/home');
  //           },2000)

  //       }else{
  //         ////console.log("result?.data?.error",result?.response?.data?.error);
  //         setSuccessMessage(result?.response?.data?.error)
  //       }

  //     }

  //   }catch(err){
  //     setErrorMessage(err.message)
  //   }
  // }

  const submitProfile = async () => {

    console.log("userDatauserData",userData,otp);
    
    setIsLoading(true);

    try {
      if (!userData?.file) {
        setErrorMessage(
          "Please upload your face photo only. This will be used for detection in photo list."
        );
        setIsLoading(false);

        return;
      }
      const check = await checKPhotoGrapherExist(otp?.email);
     
      

      const formData = new FormData();
      formData.append("files", userData?.file);
      const uploadResponse = await uploadAndCheckFace(formData);

      if (uploadResponse.data.result.status === true) {
        setErrorMessage(
          "Upload a photo of only your face; photos with multiple faces are not allowed"
        );
        setIsLoading(false);
        return;
      }
      let userDetail;
if(userData?.userType === "user"){
  userDetail = {
    name: userData.name,
    userType:check?.data?.IsExist ? "photographer":"user",
    email: appointMentPropdata.email_id,
    phone:userData?.phone,
    email:otp?.email
  };
}else{
       userDetail = {
        name: appointMentPropdata.full_name,
        email: appointMentPropdata.email_id,
        userType: check?.data?.IsExist ? "photographer":"user",
      };
    }

  
    
      const registrationFormData = new FormData();
      registrationFormData.append("userData", JSON.stringify(userDetail));
      registrationFormData.append("file", userData.file);

      const result = await Register(registrationFormData);


      if (result.status === 200) {
        const userType =result?.data?.userType;
        const UserImage = result?.data.data?.imageUrl;
        const UserId = result?.data.data?.AdditionalUsers[0];
        const datas = { data: result?.data, userType, UserImage, UserId };

        dispatch(addRegisterData(datas));
        localStorage.setItem("accessToken", result?.data?.accessToken);
        localStorage.setItem("userType", userType);
        localStorage.setItem("userId", result?.data.data?._id);
        localStorage.setItem("email", result?.data.data?.email);
        localStorage.setItem("profileImage", result?.data.data?.imageUrl);

        setSuccessMessage("Profile Completed Successfully");
        setTimeout(() => {
          const redirectToPath = location.state?.from || '/publicGroup';

          setSuccessMessage("");
          navigate(redirectToPath);
        }, 2000);
      } else {
        setErrorMessage(result?.response?.data?.error || "Registration failed");
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = () => {
    console.log("gggggg",currentStep);
    
    if (currentStep === 1) {
      EmailSubmit();
    } else if (currentStep === 2) {
      otpSubmit();
    } else {
      submitProfile();
    }
  };

  const moveNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const appointmentNextStep = (email) => {
    setCurrentStep(currentStep + 2);
    setUserEmail(email);
  };

  const appointmentdata = (data) => {
    setAppointMentPropdata(data);
    //console.log("appointData", data);
  };

  const prevStep = () => {
    if (currentStep === 3) {
      setCurrentStep(1);
    } else if (currentStep === 3) {
      setCurrentStep(1);
      setChecked(true);
    }
    setErrorMessage("");
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Login1
            nextStep={nextStep}
            Login1Data={Login1Data}
            errorMessage={errorMessage}
          />
        );
      case 2:
        return (
          // <Login2
          //   moveNextStep={moveNextStep}
          //   prevStep={prevStep}
          //   Login2Data={Login2Data}
          //   errorMessage={errorMessage}
          //   userEmail={userEmail}
          // />
          <>
          <Login2
            moveNextStep={moveNextStep}
            prevStep={prevStep}
            Login2Data={Login2Data}
            errorMessage={errorMessage}
            userEmail={userEmail}
            otpReset={otp}
          />
         
          <Button
            type="submit"
            onClick={handleResendOtp}
            fullWidth
            variant="contained"
            sx={buttonStyle}
            disabled={!canResend || isOtpResending}
          >
            {isOtpResending ? <CircularProgress size={60} /> : `Resend OTP (${resendTimer})`}
          </Button>
        </>
        );
      case 3:
        return (
          <Login4
            prevStep={prevStep}
            nextStep={nextStep}
            Login4Data={Login4Data}
            errorMessage={errorMessage}
          />
        );
      default:
        return null;
    }
  };

  const renderImage = () => {
    switch (currentStep) {
      case 1:
        return (
          <img
            src={mountanImage}
            alt=""
            style={{ height: 450, width: "100%" }}
          />
        );
      case 2:
        return (
          <img
            src={mountanImage}
            alt=""
            style={{ height: 450, width: "100%" }}
          />
        );
      case 3:
        return (
          <img
            src={mountanImage}
            alt=""
            style={{ height: 450, width: "100%" }}
          />
        );
      default:
        return null;
    }
  };

  return (
    // <div style={{
    //   width: "100vw",
    //   height: "100vh",
    //   backgroundColor: "blue"
    // }}>
    //   {/* {successMessage ? <SuccessComponent message={successMessage} /> : null} */}

    //   <div style={{ display: "flex", justifyContent: "center" }}>
    //     <Card
    //       style={{
    //         width: 800,
    //         padding: isMobileOrTablet ? 10 : "",
    //         margin: 10,
    //       }}
    //       elevation={5}
    //     >
    //       <Grid container direction={""} >

    //         <Grid item md={6} xs={12} sm={12} lg={6}>
    //           <div style={{ marginTop: "100px" }}>
    //             {currentStep === 1 && (
    //               <Stack direction={"row"}>
    //                 {/* <Checkbox
    //             checked={checked}
    //             onChange={handleChange}
    //             inputProps={{ 'aria-label': 'controlled' }}

    //             /> */}
    //                 <Typography style={{ marginTop: "10px" }}>
    //                   Login with Art of Living Appointment credentials{" "}
    //                 </Typography>
    //               </Stack>
    //             )}
    //           </div>

    //           {
    //             !checked ?
    //               <div style={{ marginTop: 10, marginRight: 10 }}>
    //                 {successMessage ? <Alert severity="success">{successMessage}</Alert> : null}
    //                 {renderStep()}
    //                 {
    //                   currentStep === 3 &&
    //                   <>
    //                     <Button onClick={nextStep} fullWidth variant='contained' style={{ marginTop: 20 }} disabled={isLoading}>

    //                       {isLoading ? <CircularProgress size={30} /> : "Continue"}

    //                     </Button>

    //                   </>

    //                 }
    //               </div>
    //               :
    //               <div style={{ marginTop: 10, marginRight: 10 }}>
    //                 <AppointMentLogin appointmentNextStep={appointmentNextStep} setChecked={setChecked} email={userEmail} appointmentdata={appointmentdata} photoGrapherList={photoGrapherList} />

    //               </div>

    //           }

    //           {
    //             currentStep === 3 && (
    //               <div style={{ marginTop: 10 }}>
    //                 <Icon size={35} icon={ic_keyboard_backspace_outline} onClick={prevStep} />
    //               </div>
    //             )
    //           }

    //         </Grid>

    //       </Grid>
    //     </Card>
    //   </div>
    // </div>
    <div
      style={{
        margin: "",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${bgimage})`,
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Poppins, sans-serif",
        overflow: "hidden",
      }}
    >
      <Card
        style={{
          margin: "15px",
          width: 400,
          height: 450,
          padding: 50,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          fontFamily: "Poppins, sans-serif",
          borderRadius: "20px",
          position: "relative",
        }}
      >
        <Grid>
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              position: "absolute",
              top: "3%",
              right: isMobileOrTablet ? "55px" : "122px",
            }}
          >
            {" "}
            <img
              style={{
                position: "absolute",
                display: "inline-block",
                top: "-8px",
                right: "190px",
                width: "40px",
                height: "40px",
              }}
              src={sumeruLogo}
              alt=""
              srcSet=""
            />
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: "#297EFF",
                marginRight: "5px",
                marginBottom: "20px",
              }}
            >
               Divine Picture
            </Typography>
            <Typography
              // variant="caption"
              component="div"
              sx={{
                textAlign: "center",
                fontSize: "8px",
                height: "12px",
                marginRight:"15px",
                width: "35px",
                border: "1px solid #297EFF",
                borderRadius: "10px",
                // padding: "2px 5px",
                fontWeight: "bold",
                color: "#297EFF",
              }}
            >
              BETA
            </Typography>
          </Box>
        </Grid>

        <Stack direction={"column"} spacing={3}>
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            Sign in to your account
          </Typography>

          {successMessage && <Alert severity="success">{successMessage}</Alert>}

          {currentStep === 1 && (
            <Stack direction="row" alignItems="center">
              <Typography style={{ marginLeft: isMobile ? "0px" : "10px", fontWeight: "600" }}>
                Login with Art of Living Appointment credentials
              </Typography>
            </Stack>
          )}
        </Stack>

        {!checked ? (
          <>
            {renderStep()}
            {/* {currentStep === 3 && ( */}
              <Button
                type="submit"
                onClick={nextStep}
                fullWidth
                variant="contained"
                sx={buttonStyle}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={30} /> : "Continue"}
              </Button>
              
            {/* )} */}
          </>
        ) : (
          <AppointMentLogin
            appointmentNextStep={appointmentNextStep}
            setChecked={setChecked}
            email={userEmail}
            appointmentdata={appointmentdata}
            photoGrapherList={photoGrapherList}
          />
        )}
{/* <button onClick={()=>setChecked(!checked)}>ghfghfgh</button> */}


{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
  <Button 
    onClick={() => setChecked(!checked)} 
    style={{ 
      color: '#007BFF', 
      //textDecoration: 'underline', 
      background: 'none', 
      fontSize: '11px', 
      fontWeight: 'bold',
      cursor: 'pointer',
      padding: '10px 20px',
    }}
  >
    Sign-in as a Photographer or Admin
  </Button>

  
</div> */}



{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {showLogin ? (
        <Button
        onClick={() => setChecked(!checked)} 
          style={{
            color: '#007BFF',
            textDecoration: 'underline',
            background: 'none',
            fontSize: '13px',
            fontWeight: 'bold',
            cursor: 'pointer',
            padding: '10px 20px',
          }}
        >
          Log in as a Photographer or Admin
        </Button>
      ) : (
        <Button
        onClick={() => setChecked(!checked)} 
          style={{
            color: '#007BFF',
            textDecoration: 'underline',
            background: 'none',
            fontSize: '13px',
            fontWeight: 'bold',
            cursor: 'pointer',
            padding: '10px 20px',
          }}
        >
          Back
        </Button>
      )}
    </div> */}  


<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {showLogin ? (
        <Button
          onClick={handleToggle}
          style={{
            color: '#007BFF',
            textDecoration: 'underline',
            background: 'none',
            fontSize: '13px',
            fontWeight: 'bold',
            cursor: 'pointer',
            padding: '10px 20px',
            marginBottom:'-30%',
          }}
        >
          Login as a Photographer or Admin
        </Button>
      ) : (
        <Button
          onClick={handleToggle}
          style={{
            color: '#007BFF',
            textDecoration: 'underline',
            background: 'none',
            fontSize: '13px',
            fontWeight: 'bold',
            cursor: 'pointer',
            padding: '10px 20px',
            marginBottom:'-30%',
          }}
        >
         
          <ArrowBackIcon />  Back
        </Button>
      )}
    </div>




        {currentStep === 3 && (
          <div
            style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
          >
            {/* <Icon
              size={35}
              icon={ic_keyboard_backspace_outline}
              onClick={prevStep}
            /> */}
          </div>
        )}
      </Card>

    </div>
  );
}

const buttonStyle = {
  marginTop: "16px",
  borderColor: "#DB4437",
  color: "#DB4437",
  padding: "12px 20px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
    color: "#fff",
  },
};

export default Login;




// import React, { useEffect, useState } from "react";

// import Login1 from "../Auth/Login1";
// import Login2 from "../Auth/Login2";
// import {
//   Alert,
//   Box,
//   Button,
//   Card,
//   Checkbox,
//   CircularProgress,
//   FormControlLabel,
//   Grid,
//   Stack,
//   TextField,
//   Typography,
// } from "@mui/material";
// import mountanImage from "../../images/vishal.png";
// import Login4 from "../Auth/Login4";
// import { Register, checKPhotoGrapherExist, otpSend, otpVerify } from "../../API/Auth";
// import { json, useNavigate, useNavigation } from "react-router";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import { BackHand } from "@mui/icons-material";
// import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
// import { ic_keyboard_backspace_outline } from "react-icons-kit/md/ic_keyboard_backspace_outline";
// import Icon from "react-icons-kit";
// import AppointMentLogin from "../Auth/AppointmentLogin";
// import { getphotographerList } from "../../API/Admin";
// import { useDispatch } from "react-redux";
// import { addRegisterData } from "../../Store/action";
// import { uploadAndCheckFace } from "../../API/Photographer";
// import { Link } from "react-router-dom";
// import bgimage from "../../images/divine-pic-login.svg";
// import sumeruLogo from "../../images/sumeru-logo.jpeg";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// function Login() {


//   const [showLogin, setShowLogin] = useState(true);

//   const handleToggle = () => {
//     setChecked(!checked); 
//     setShowLogin(!showLogin); 
//   };


//   const [currentStep, setCurrentStep] = useState(1);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [userEmail, setUserEmail] = useState("");
//   const [userData, setUserData] = useState("");
//   const [appointMentPropdata, setAppointMentPropdata] = useState("");
//   const [otp, setOtp] = useState("");
//   const theme = useTheme();
//   const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("700"));
//   const isMobile = useMediaQuery(theme.breakpoints.down("420"));
//   const [successMessage, setSuccessMessage] = useState("");
//   const [checked, setChecked] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);

//   const [allPhotographer, setAllphotoGrapher] = useState([]);
//   const dispatch = useDispatch();

//   const leftPosition = isMobile ? "50px" : isMobileOrTablet ? "50px" : "112px";

//   const handleChange = (event) => {
//     setChecked(event.target.checked);
//   };
//   const navigate = useNavigate("");

//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (event.key === "Enter") {
//         nextStep();
//       }
//     };

//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [currentStep]);

//   const Login1Data = (data) => {
//     setErrorMessage("");
//     setUserEmail(data);
//   };

//   const Login2Data = (data) => {
//     setErrorMessage("");
//     setOtp(data);
//   };

//   const Login4Data = (data) => {
//     setErrorMessage("");
//     setSuccessMessage("");
//     setUserData(data);
//   };

//   const EmailSubmit = async () => {
//     const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!userEmail) {
//       setErrorMessage("Email is required");
//     } else if (!emailFormat.test(userEmail)) {
//       ////console.log("Invalid Email format");
//       setErrorMessage("Invalid Email format");
//     } else {
//       try {
//         const data = {
//           email: userEmail,
//         };
//         const response = await otpSend(data);
//         console.log("responseresponseqwr",response);
        
//         if (response.status === 201) {
//           setErrorMessage("");
//           setCurrentStep(currentStep + 1);
//         }else{

//        if (response?.response?.data?.Message === 'Not allow to other than user') {  
//           setErrorMessage("Not allow to other than user");
//         } else { 
//           setErrorMessage("An unexpected error occurred");
//         }
//       }

//       } catch (err) {
//         console.error(err);
//         setErrorMessage(err.message);
//       }
//     }
//   };

//   const otpSubmit = async () => { };
//   const photoGrapherList = (data) => {
//     //console.log("photoGrapherList", data);
//     setAllphotoGrapher(data);
//   };

//   // const submitProfile=async()=>{
//   //   //console.log("UserDetailes",userData)
//   //   try{
//   //      if(userData?.file===null){
//   //       setErrorMessage("Profile photo is required.");
//   //     }
//   //     // else if(userData.email=== "" || userData.email === undefined){
//   //     //   setErrorMessage("Email is required");
//   //     // }else if(!emailFormat.test(userData.email)){
//   //     //   setErrorMessage("Email is invalid format");
//   //     // }
//   //     // else if(userData?.file === null){
//   //     //   setErrorMessage("File is required");
//   //     // }else if(userData?.file?.type != 'image/jpeg' && userData?.file?.type != 'image/png' && userData?.file?.type != 'image/jpg'){
//   //     //   setErrorMessage("Select is valid format image");
//   //     // }
//   //     else{
//   //       const formdata= new FormData();
//   //       const userDeatail={
//   //         name:appointMentPropdata.full_name,
//   //         email: appointMentPropdata.email_id,
//   //         // phone:userData.phone || "",
//   //         userType:appointMentPropdata.user_role

//   //       }
//   //       //console.log("userDeataiqwertyl",userDeatail)
//   //       formdata.append("userData",JSON.stringify(userDeatail));
//   //       formdata.append("file",userData.file)
//   //        const result = await Register(formdata);
//   //        //console.log(result);
//   //         //console.log("result",result)

//   //       if(result.status === 200){

//   //         let userType=""
//   //         if(allPhotographer===true){
//   //           userType="photographer"
//   //         }
//   //         else{
//   //           userType=result?.data?.userType
//   //         }
//   //         const UserImage=result?.data.data?.imageUrl
//   //         const UserId=result?.data.data?.AdditionalUsers[0]
//   //         const datas={data:result?.data,userType,UserImage,UserId }

//   //         dispatch(addRegisterData(datas))
//   //         localStorage.clear();
//   //         localStorage.setItem("accessToken",result?.data?.accessToken);
//   //         localStorage.setItem("userType",result?.data?.userType);
//   //         // localStorage.setItem("userType" , "photographer");

//   //         localStorage.setItem("userId",result?.data.data?._id);
//   //         localStorage.setItem("email",result?.data.data?.email);
//   //         localStorage.setItem("profileImage",result?.data.data?.imageUrl);
//   //         if(allPhotographer===true){
//   //           //console.log("Passed")
//   //           localStorage.setItem("userType","photographer");
//   //          }
//   //      else{
//   //        //console.log("NotPassed")
//   //         localStorage.setItem("userType", result?.data?.userType);
//   //        // localStorage.setItem("userType", "admin");

//   //      }
//   //         setSuccessMessage("Profile Completed Successfully")
//   //           setTimeout(()=>{
//   //             setSuccessMessage('');
//   //             navigate('/home');
//   //           },2000)

//   //       }else{
//   //         ////console.log("result?.data?.error",result?.response?.data?.error);
//   //         setSuccessMessage(result?.response?.data?.error)
//   //       }

//   //     }

//   //   }catch(err){
//   //     setErrorMessage(err.message)
//   //   }
//   // }

//   const submitProfile = async () => {

//     console.log("userDatauserData",userData,otp);
    
//     setIsLoading(true);

//     try {
//       if (!userData?.file) {
//         setErrorMessage(
//           "Please upload your face photo only. This will be used for detection in photo list."
//         );
//         setIsLoading(false);

//         return;
//       }
//       const check = await checKPhotoGrapherExist(otp?.email);
     
      

//       const formData = new FormData();
//       formData.append("files", userData?.file);
//       const uploadResponse = await uploadAndCheckFace(formData);

//       if (uploadResponse.data.result.status === true) {
//         setErrorMessage(
//           "Please upload a photo of yourself showing only your face"
//         );
//         setIsLoading(false);
//         return;
//       }
//       let userDetail;
// if(userData?.userType === "user"){
//   userDetail = {
//     name: userData.name,
//     userType:check?.data?.IsExist ? "photographer":"user",
//     email: appointMentPropdata.email_id,
//     phone:userData?.phone,
//     email:otp?.email
//   };
// }else{
//        userDetail = {
//         name: appointMentPropdata.full_name,
//         email: appointMentPropdata.email_id,
//         userType: check?.data?.IsExist ? "photographer":"user",
//       };
//     }

  
    
//       const registrationFormData = new FormData();
//       registrationFormData.append("userData", JSON.stringify(userDetail));
//       registrationFormData.append("file", userData.file);

//       const result = await Register(registrationFormData);


//       if (result.status === 200) {
//         const userType =result?.data?.userType;
//         const UserImage = result?.data.data?.imageUrl;
//         const UserId = result?.data.data?.AdditionalUsers[0];
//         const datas = { data: result?.data, userType, UserImage, UserId };

//         dispatch(addRegisterData(datas));
//         localStorage.setItem("accessToken", result?.data?.accessToken);
//         localStorage.setItem("userType", userType);
//         localStorage.setItem("userId", result?.data.data?._id);
//         localStorage.setItem("email", result?.data.data?.email);
//         localStorage.setItem("profileImage", result?.data.data?.imageUrl);

//         setSuccessMessage("Profile Completed Successfully");
//         setTimeout(() => {
//           setSuccessMessage("");
//           navigate("/publicGroup");
//         }, 2000);
//       } else {
//         setErrorMessage(result?.response?.data?.error || "Registration failed");
//       }
//     } catch (err) {
//       setErrorMessage(err.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const nextStep = () => {
//     if (currentStep === 1) {
//       EmailSubmit();
//     } else if (currentStep === 2) {
//       otpSubmit();
//     } else {
//       submitProfile();
//     }
//   };

//   const moveNextStep = () => {
//     setCurrentStep(currentStep + 1);
//   };

//   const appointmentNextStep = (email) => {
//     setCurrentStep(currentStep + 2);
//     setUserEmail(email);
//   };

//   const appointmentdata = (data) => {
//     setAppointMentPropdata(data);
//     //console.log("appointData", data);
//   };

//   const prevStep = () => {
//     if (currentStep === 3) {
//       setCurrentStep(1);
//     } else if (currentStep === 3) {
//       setCurrentStep(1);
//       setChecked(true);
//     }
//     setErrorMessage("");
//   };

//   const renderStep = () => {
//     switch (currentStep) {
//       case 1:
//         return (
//           <Login1
//             nextStep={nextStep}
//             Login1Data={Login1Data}
//             errorMessage={errorMessage}
//           />
//         );
//       case 2:
//         return (
//           <Login2
//             moveNextStep={moveNextStep}
//             prevStep={prevStep}
//             Login2Data={Login2Data}
//             errorMessage={errorMessage}
//             userEmail={userEmail}
//           />
//         );
//       case 3:
//         return (
//           <Login4
//             prevStep={prevStep}
//             nextStep={nextStep}
//             Login4Data={Login4Data}
//             errorMessage={errorMessage}
//           />
//         );
//       default:
//         return null;
//     }
//   };

//   const renderImage = () => {
//     switch (currentStep) {
//       case 1:
//         return (
//           <img
//             src={mountanImage}
//             alt=""
//             style={{ height: 450, width: "100%" }}
//           />
//         );
//       case 2:
//         return (
//           <img
//             src={mountanImage}
//             alt=""
//             style={{ height: 450, width: "100%" }}
//           />
//         );
//       case 3:
//         return (
//           <img
//             src={mountanImage}
//             alt=""
//             style={{ height: 450, width: "100%" }}
//           />
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     // <div style={{
//     //   width: "100vw",
//     //   height: "100vh",
//     //   backgroundColor: "blue"
//     // }}>
//     //   {/* {successMessage ? <SuccessComponent message={successMessage} /> : null} */}

//     //   <div style={{ display: "flex", justifyContent: "center" }}>
//     //     <Card
//     //       style={{
//     //         width: 800,
//     //         padding: isMobileOrTablet ? 10 : "",
//     //         margin: 10,
//     //       }}
//     //       elevation={5}
//     //     >
//     //       <Grid container direction={""} >

//     //         <Grid item md={6} xs={12} sm={12} lg={6}>
//     //           <div style={{ marginTop: "100px" }}>
//     //             {currentStep === 1 && (
//     //               <Stack direction={"row"}>
//     //                 {/* <Checkbox
//     //             checked={checked}
//     //             onChange={handleChange}
//     //             inputProps={{ 'aria-label': 'controlled' }}

//     //             /> */}
//     //                 <Typography style={{ marginTop: "10px" }}>
//     //                   Login with Art of Living Appointment credentials{" "}
//     //                 </Typography>
//     //               </Stack>
//     //             )}
//     //           </div>

//     //           {
//     //             !checked ?
//     //               <div style={{ marginTop: 10, marginRight: 10 }}>
//     //                 {successMessage ? <Alert severity="success">{successMessage}</Alert> : null}
//     //                 {renderStep()}
//     //                 {
//     //                   currentStep === 3 &&
//     //                   <>
//     //                     <Button onClick={nextStep} fullWidth variant='contained' style={{ marginTop: 20 }} disabled={isLoading}>

//     //                       {isLoading ? <CircularProgress size={30} /> : "Continue"}

//     //                     </Button>

//     //                   </>

//     //                 }
//     //               </div>
//     //               :
//     //               <div style={{ marginTop: 10, marginRight: 10 }}>
//     //                 <AppointMentLogin appointmentNextStep={appointmentNextStep} setChecked={setChecked} email={userEmail} appointmentdata={appointmentdata} photoGrapherList={photoGrapherList} />

//     //               </div>

//     //           }

//     //           {
//     //             currentStep === 3 && (
//     //               <div style={{ marginTop: 10 }}>
//     //                 <Icon size={35} icon={ic_keyboard_backspace_outline} onClick={prevStep} />
//     //               </div>
//     //             )
//     //           }

//     //         </Grid>

//     //       </Grid>
//     //     </Card>
//     //   </div>
//     // </div>
//     <div
//       style={{
//         margin: "",
//         width: "100vw",
//         height: "100vh",
//         backgroundImage: `url(${bgimage})`,
//         backgroundSize: "cover",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         fontFamily: "Poppins, sans-serif",
//         overflow: "hidden",
//       }}
//     >
//       <Card
//         style={{
//           margin: "15px",
//           width: 400,
//           height: 450,
//           padding: 50,
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//           fontFamily: "Poppins, sans-serif",
//           borderRadius: "20px",
//           position: "relative",
//         }}
//       >
//         <Grid>
//           <Box
//             sx={{
//               display: "flex",
//               alignContent: "center",
//               justifyContent: "center",
//               position: "absolute",
//               top: "3%",
//               right: isMobileOrTablet ? "55px" : "122px",
//             }}
//           >
//             {" "}
//             <img
//               style={{
//                 position: "absolute",
//                 display: "inline-block",
//                 top: "-8px",
//                 right: "190px",
//                 width: "40px",
//                 height: "40px",
//               }}
//               src={sumeruLogo}
//               alt=""
//               srcSet=""
//             />
//             <Typography
//               variant="h6"
//               component="div"
//               sx={{
//                 color: "#297EFF",
//                 marginRight: "5px",
//                 marginBottom: "20px",
//               }}
//             >
//                Divine Picture
//             </Typography>
//             <Typography
//               // variant="caption"
//               component="div"
//               sx={{
//                 textAlign: "center",
//                 fontSize: "8px",
//                 height: "12px",
//                 marginRight:"15px",
//                 width: "35px",
//                 border: "1px solid #297EFF",
//                 borderRadius: "10px",
//                 // padding: "2px 5px",
//                 fontWeight: "bold",
//                 color: "#297EFF",
//               }}
//             >
//               BETA
//             </Typography>
//           </Box>
//         </Grid>

//         <Stack direction={"column"} spacing={3}>
//           <Typography
//             variant="h5"
//             align="center"
//             gutterBottom
//             style={{ fontWeight: "bold" }}
//           >
//             Sign in to your account
//           </Typography>

//           {successMessage && <Alert severity="success">{successMessage}</Alert>}

//           {currentStep === 1 && (
//             <Stack direction="row" alignItems="center">
//               <Typography style={{ marginLeft: isMobile ? "0px" : "10px", fontWeight: "600" }}>
//                 Login with Art of Living Appointment credentials
//               </Typography>
//             </Stack>
//           )}
//         </Stack>

//         {!checked ? (
//           <>
//             {renderStep()}
//             {/* {currentStep === 3 && ( */}
//               <Button
//                 type="submit"
//                 onClick={nextStep}
//                 fullWidth
//                 variant="contained"
//                 style={{
//                   marginTop: 30,
//                   backgroundColor: "#297EFF",
//                   color: "#fff",
//                 }}
//                 disabled={isLoading}
//               >
//                 {isLoading ? <CircularProgress size={30} /> : "Continue"}
//               </Button>
              
//             {/* )} */}
//           </>
//         ) : (
//           <AppointMentLogin
//             appointmentNextStep={appointmentNextStep}
//             setChecked={setChecked}
//             email={userEmail}
//             appointmentdata={appointmentdata}
//             photoGrapherList={photoGrapherList}
//           />
//         )}
// {/* <button onClick={()=>setChecked(!checked)}>ghfghfgh</button> */}


// {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
//   <Button 
//     onClick={() => setChecked(!checked)} 
//     style={{ 
//       color: '#007BFF', 
//       //textDecoration: 'underline', 
//       background: 'none', 
//       fontSize: '11px', 
//       fontWeight: 'bold',
//       cursor: 'pointer',
//       padding: '10px 20px',
//     }}
//   >
//     Sign-in as a Photographer or Admin
//   </Button>

  
// </div> */}



// {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//       {showLogin ? (
//         <Button
//         onClick={() => setChecked(!checked)} 
//           style={{
//             color: '#007BFF',
//             textDecoration: 'underline',
//             background: 'none',
//             fontSize: '13px',
//             fontWeight: 'bold',
//             cursor: 'pointer',
//             padding: '10px 20px',
//           }}
//         >
//           Log in as a Photographer or Admin
//         </Button>
//       ) : (
//         <Button
//         onClick={() => setChecked(!checked)} 
//           style={{
//             color: '#007BFF',
//             textDecoration: 'underline',
//             background: 'none',
//             fontSize: '13px',
//             fontWeight: 'bold',
//             cursor: 'pointer',
//             padding: '10px 20px',
//           }}
//         >
//           Back
//         </Button>
//       )}
//     </div> */}  


// <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//       {showLogin ? (
//         <Button
//           onClick={handleToggle}
//           style={{
//             color: '#007BFF',
//             textDecoration: 'underline',
//             background: 'none',
//             fontSize: '13px',
//             fontWeight: 'bold',
//             cursor: 'pointer',
//             padding: '10px 20px',
//             marginBottom:'-30%',
//           }}
//         >
//           Log in as a Photographer or Admin
//         </Button>
//       ) : (
//         <Button
//           onClick={handleToggle}
//           style={{
//             color: '#007BFF',
//             textDecoration: 'underline',
//             background: 'none',
//             fontSize: '13px',
//             fontWeight: 'bold',
//             cursor: 'pointer',
//             padding: '10px 20px',
//             marginBottom:'-30%',
//           }}
//         >
         
//           <ArrowBackIcon />  Back
//         </Button>
//       )}
//     </div>




//         {currentStep === 3 && (
//           <div
//             style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
//           >
//             {/* <Icon
//               size={35}
//               icon={ic_keyboard_backspace_outline}
//               onClick={prevStep}
//             /> */}
//           </div>
//         )}
//       </Card>

//     </div>
//   );
// }

// export default Login;





