import baseApi from "./baseApi"

export const sendPayment=async(data)=>{
   const AccesToken = localStorage.getItem("accessToken");
   //console.log("AccesToken",AccesToken)
   try {
      let config = {
         headers: {
            'Authorization': `Bearer ${AccesToken}`,
         }
       };
    const response= await baseApi.post('/subscribe/paymentOrder',data, config)
    return response



   } catch (error) {
      //console.log("error",error)
      return error
    
   }
}

export const verifyPayment=async(data,amount,imagesToAdd,plan)=>{
   //console.log("amount,imagesToAdd",amount,imagesToAdd)
   const AccesToken = localStorage.getItem("accessToken");
    try {
      let config = {
         headers: {
            'Authorization': `Bearer ${AccesToken}`,
         }
       };
     
     const response= await baseApi.post(`/subscribe/verifyPayment?imagesToAdd=${imagesToAdd}&amount=${amount}&plan=${plan}`,data,config)
     return response
    } catch (error) {
     return error
    }
 }

