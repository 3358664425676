

import React, { useEffect, useState } from 'react';

import { Button, Stack, Typography,Card, Divider,Box,Grid  } from '@mui/material';
import { sendPayment, verifyPayment } from '../API/Subscription';
import Modal from '@mui/material/Modal';
import { getSubscription } from '../API/Admin';
function SubscriptionPay({subscriptionStatus,subscriptionMessage}) {
	const [open, setOpen] = useState(false);
	const [status, setStatus] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [subscriptionList, setSubscriptionList] = useState([]);
	
	//console.log("status",status)
	subscriptionMessage(status)
	const initPayment = (data,amounts,imagesToAdd,plan) => {
		
		//console.log("data123",data)
		//console.log("id",imagesToAdd)
		const options = {
			// key: "YOUR_RAZORPAY_KEY",
			amount: data?.amount,
			currency: data?.currency,
			// name: book.name,
			description: "Test Transaction",
			//  image: 200,
			order_id: data?.id,
			handler: async (response) => {
				try {
				
					setStatus(true)
					//console.log("data1234")
					const res=await verifyPayment(response,amounts,imagesToAdd,plan)
					//console.log("res",res)
					//console.log("res",res?.status)
					setStatus(false)
					//console.log("res",status)
					subscriptionMessage(status)
					
					// subscriptionStatus(res?.status)
					handleClose()
				} catch (error) {
					//console.log(error);
				}
			},
			theme: {
				color: "#3399cc",
			},
		};
		const rzp1 = new window.Razorpay(options);
		rzp1.open();
		subscriptionStatus(200)
	};

	const handlePayment = async (amounts,imagesToAdd,plan) => {
		//console.log(amounts)
		
		try {
			
			const data={
				amount :amounts
			}
			const response=await sendPayment(data)
			//console.log("response",response);
			
			// //console.log("response?.data?.data",response?.data?.message);
			handleClose()
			
			const propsData=response?.data?.data
			
			initPayment(propsData,amounts,imagesToAdd,plan);
		} catch (error) {
			//console.log(error);
		}
	};

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		 width: 950,
		 height:500,
		bgcolor: 'background.paper',
		borderRadius: 2,
		boxShadow: 24,
		p: 4,
	 
		overflow: 'auto', 
		maxHeight: '80vh'
	  };


	  const getsubscriptionPlan=async()=>{
		const user="photographer"
			try {
			  const response=await getSubscription(user)
			  //console.log("response",response)
			  setSubscriptionList(response?.data?.data)
			} catch (error) {
			  //console.log(error)
			}
		 }

		 useEffect(()=>{
			getsubscriptionPlan()
		   },[])
	return (
		<div >
			
    <div>
		<div style={{display:'flex',justifyContent:'center'}}>
		<Button onClick={handleOpen} variant='outlined'  style={{backgroundColor:"#AD902B",color:"white" ,fontWeight:"bold"}} >Upgrade your Plans</Button>
		</div>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
        <Typography variant='h6'>Select Plan</Typography> 
        <Divider/>  
		<Grid container spacing={2} marginTop={1}>
            {subscriptionList?.map((item, index) => (
              <Grid item xs={12} sm={12} md={4} lg={4} key={item._id || index}>
                <Card elevation={3} style={{ padding: 20, width: '80%', backgroundColor: "#DCDCDC" }} onClick={() => handlePayment(item.price, item.upload,item.name)}>
                 <Typography variant="h6" component="h2" style={{ fontWeight: "bold" }}>
                    {item.name}
                 </Typography>
                 <Typography variant="h6" component="h2" style={{ fontWeight: "bold" }}>
                    {item.price}
                 </Typography>
                 <Typography>
                    You can upload {item.upload} images
                 </Typography>
                 <Button variant='outlined' fullWidth style={{ marginTop: 25 }}>Select</Button>
                </Card>
              </Grid>
            ))}
          </Grid>
          
        </Box>
        
      </Modal>
    </div>
		</div>
	);
}

export default SubscriptionPay;


