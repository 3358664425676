import axios from 'axios';
import apiInstance from './baseApi';
export const otpSend = async (user) => {
try{
    const response = await apiInstance.post('/Auth/login',user);
      return response;
} catch(err){
    ////console.log("error",err);
    return err;
}
}

export const otpVerify = async (user) => {
try{
    const response = await apiInstance.post('/Auth/OtpVerify',user);
      return response;
} catch(err){
    ////console.log("gjgjg",err);
    return err;
}
}

export const Register = async (user) => {
  //console.log(user , '123456123456789');
    try{
        const response = await apiInstance.post('/Auth/Register',user);
        //console.log(response , 'responnnnnnnnnnnnse');
          return response;
    } catch(err){
        ////console.log("gjgjg",err);
        return err;
    }
    }


    export const AppointmentLoginApi = async (data) => {
      const datas = {
        email_id: data.email_id,
        password: data.password,
      };

        try{
            const response = await axios.post('https://www.artofliving.io/API/DivinePic/user_login_new', datas, 
                // {withCredentials: false},
                {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              }
            );
              return response;
        } catch(err){
            //console.log("gjgjg",err);
            return err;
        }
        }


        
        export const checkApiForAppointmentUser = async (data) => {
          try{
            const loginType="appointment"
            const response = await apiInstance.post(`/Auth/OtpVerify?loginType=${loginType}`,data);
                return response;
          } catch(err){
              ////console.log("gjgjg",err);
              return err;
          }
          }


            
        export const addUser = async (data) => {
          try{
           const AccesToken = localStorage.getItem("accessToken");
       
            let config = {
                headers: {
                  'Authorization': `Bearer ${AccesToken}`,
                }
              };
            const response = await apiInstance.post(`/Auth/addUser`,data,config);
                return response;
          } catch(err){
              ////console.log("gjgjg",err);
              return err;
          }
          }


          export const getaddedUser = async () => {
            try{
             const AccesToken = localStorage.getItem("accessToken");
             const id = localStorage.getItem("userId");
         
              let config = {
                  headers: {
                    'Authorization': `Bearer ${AccesToken}`,
                  }
                };
              const response = await apiInstance.get(`/Auth/getAddeduser?Id=${id}`,config);
                  return response;
            } catch(err){
                ////console.log("gjgjg",err);
                return err;
            }
            }
      
          
            export const checKPhotoGrapherExist = async (data) => {
              //console.log("emailData",data)
              try{
             
                const response = await apiInstance.get(`/Auth/checkPotographerExist?email=${data}`);
                return response;
              } catch(err){
                  ////console.log("gjgjg",err);
                  return err;
              }
              }
              export const deleteUser = async (userId) => {
                console.log(userId, "deleteUserID");
                try {
                  const response = await apiInstance.delete(`/Auth/delete/${userId}`);
                  //console.log(response, 'deleteUserResponse');
                  return response;
                } catch (err) {
                  //console.log("error", err);
                  return err;
                }
              }
              

// Update User Profile
export const updateUserProfile = async (userId, updates) => {
  //console.log(userId, updates, 'updateUserProfile');
  const AccesToken = localStorage.getItem("accessToken");

  try {
    let config = {
      headers: {
        'Authorization': `Bearer ${AccesToken}`,
      }
    };
    const response = await apiInstance.patch(`/file/updatedUser/${userId}`, updates , config);
    //console.log(response, 'updateUserProfile response');
    return response;
  } catch (err) {
    //console.log("error", err);
    return err;
  }
}

              