  // import { InputLabel, TextField, Typography} from '@mui/material';
  // import React from 'react';
  // import { useState } from 'react';
  // import useMediaQuery from '@mui/material/useMediaQuery';
  // import { useTheme } from '@mui/material/styles';

  // export default function Login1({Login1Data,errorMessage}) {
  //   const [email,setEmail]=useState('')
  //   const theme = useTheme();
  //   const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  // const handleChange = (e) => {
  //   const trimmedValue=e.target.value.trim()
  //     setEmail(trimmedValue)
  //     Login1Data(trimmedValue)
      
  // };

  // return (
  //     <div style={{ marginTop: 10 }}>

  //       <InputLabel>Email <span style={{color:'red'}}>*</span></InputLabel>
  //       <TextField placeholder='Enter Your Email' style={{ width: "100%",marginTop:2 }}  value={email}onChange={handleChange} />
  //       {
  //           errorMessage ? <Typography style={{textAlign:"left" ,color:"red"}}>{errorMessage}</Typography>: null
  //       } 
        

  //     </div>
  // );
  // }

  import { Alert, InputLabel, TextField } from '@mui/material';
  import React, { useState } from 'react';
  import useMediaQuery from '@mui/material/useMediaQuery';
  import { useTheme } from '@mui/material/styles';
  
  export default function Login1({ Login1Data, errorMessage }) {
    const [email, setEmail] = useState('');
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  
    const handleChange = (e) => {
      const trimmedValue = e.target.value.trim();
      setEmail(trimmedValue);
      Login1Data(trimmedValue);
    };
  
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleChange(e);
      }
    };
  
    return (
      <div style={{ marginTop: 50 }}>
        {errorMessage && (
          <Alert severity="error" style={{ marginTop: 2, marginBottom: 10 }}>
            {errorMessage}
          </Alert>
        )}
        <InputLabel>
          Email <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          placeholder="Enter Your Email"
          style={{ width: '100%', marginTop: 8 }}
          value={email}
          onChange={handleChange}
          onKeyPress={handleKeyPress} // Listen for "Enter" key press
        />
      </div>
    );
  }

