import React, { useState, useEffect, useRef } from "react";
import { IconButton, Typography, Stack } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

const RangeDatePicker = ({ filterDate, width }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pickerOpen, setPickerOpen] = useState(false);
  const datePickerRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setPickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    if (endDate && new Date(newStartDate) > new Date(endDate)) {
      setEndDate("");
    }
    if (newStartDate && endDate) {
      const da = newStartDate + "#" + endDate;
      filterDate(da);
      setPickerOpen(false);
    }
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    if (!startDate || new Date(newEndDate) >= new Date(startDate)) {
      setEndDate(newEndDate);
    }
    if (startDate && newEndDate) {
      const da = startDate + "#" + newEndDate;
      filterDate(da);
      setPickerOpen(false);
    }
  };

  const togglePicker = () => {
    setPickerOpen(!pickerOpen);
  };

  return (
    <div ref={datePickerRef} >
      {!pickerOpen && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"

          onClick={togglePicker}
          style={{
            // position:"absolute",
            backgroundColor: "#ffffff",
            border: "1px solid #D4D4D4",
            borderRadius: "5px",
            height: "40px",
            width: "100px",
            cursor: "pointer"
          }}
        >
          <IconButton aria-label="filter">
            <FilterListIcon />
          </IconButton>
          <Typography>Filter</Typography>
        </Stack>
      )}
      <div
        style={{
          display: pickerOpen ? "flex" : "none",
          gap: "10px",
          maxHeight: pickerOpen ? "200px" : "0",
          overflow: "hidden",
          transition: "opacity 0.8s ease-in-out, max-height 0.8s ease-in-out",
          opacity: pickerOpen ? 1 : 0,
        }}
      >
        <input
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          style={{
            marginTop: "10px",
            padding: "10px",
            fontFamily: "Arial, sans-serif",
            fontSize: "16px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            width: "calc(50% - 10px)",
            boxSizing: "border-box",
            cursor: "pointer",
          }}
          min="1970-01-01"
          max={endDate ? endDate : ""}
        />
        <input
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          style={{
            marginTop: "10px",
            padding: "10px",
            fontFamily: "Arial, sans-serif",
            fontSize: "16px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            width: "calc(50% - 10px)",
            boxSizing: "border-box",
            cursor: "pointer",
          }}
          min={startDate ? startDate : "1970-01-01"}
          max={new Date().toISOString().split("T")[0]}
        />
      </div>
    </div>
  );
};

export default RangeDatePicker;





// import React, { useState, useEffect, useRef } from 'react';
// import { Input } from '@mui/material';

// const RangeDatePicker = ({ filterDate, width }) => {
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [pickerOpen, setPickerOpen] = useState(false);
//   const datePickerRef = useRef();

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
//         setPickerOpen(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);

//   const handleStartDateChange = (event) => {
//     const newStartDate = event.target.value;
//     setStartDate(newStartDate);
//     if (endDate && new Date(newStartDate) > new Date(endDate)) {
//       setEndDate('');
//     }
//     if (newStartDate && endDate) {
//       const da = newStartDate + "#" + endDate;
//       filterDate(da);
//       setPickerOpen(false);
//     }
//   };

//   const handleEndDateChange = (event) => {
//     const newEndDate = event.target.value;
//     if (!startDate || new Date(newEndDate) >= new Date(startDate)) {
//       setEndDate(newEndDate);
//     }
//     if (startDate && newEndDate) {
//       const da = startDate + "#" + newEndDate;
//       filterDate(da);
//       setPickerOpen(false);
//     }
//   };

//   const togglePicker = () => {
//     setPickerOpen(!pickerOpen);
//   };

//   return (
//     <div ref={datePickerRef} style={{ width: width ? width : "100%" }}>
//       <Input
//         type="text"
//         placeholder="Filter Album"
//         onClick={togglePicker}
//         readOnly
//         style={{ width: '95%', height: '40px' }}
//         value={startDate && endDate ? `${startDate} - ${endDate}` : ''}
//       />
//       <div
//         style={{
//           display: pickerOpen ? 'flex' : 'none',
//           gap: '10px',
//           opacity: pickerOpen ? 1 : 0,
//           maxHeight: pickerOpen ? '200px' : '0',
//           overflow: 'hidden',
//           // transition: 'opacity 0.8s ease-in-out, max-height 0.8s ease-in-out'
//         }}
//       >
//         <input
//           type="date"
//           value={startDate}
//           onChange={handleStartDateChange}
//           style={{
//             marginTop: '10px',
//             padding: '10px',
//             fontFamily: 'Arial, sans-serif',
//             fontSize: '16px',
//             borderRadius: '5px',
//             border: '1px solid #ccc',
//             width: 'calc(50% - 10px)',
//             boxSizing: 'border-box',
//             cursor: 'pointer'
//           }}
//           min="1970-01-01"
//           max={endDate ? endDate : ''}
//         />
//         <input
//           type="date"
//           value={endDate}
//           onChange={handleEndDateChange}
//           style={{
//             marginTop: '10px',
//             padding: '10px',
//             fontFamily: 'Arial, sans-serif',
//             fontSize: '16px',
//             borderRadius: '5px',
//             border: '1px solid #ccc',
//             width: 'calc(50% - 10px)',
//             boxSizing: 'border-box',
//             cursor: 'pointer'
//           }}
//           min={startDate ? startDate : '1970-01-01'}
//           max={new Date().toISOString().split('T')[0]}
//         />
//       </div>
//     </div>
//   );
// };

// export default RangeDatePicker;
