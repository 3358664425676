import React from 'react'
import Groups from './Groups'

export default function Home() {
  return (
    <div>   
      <Groups/>
    </div>
    
  )
}
